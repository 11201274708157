import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import intl from 'react-intl-universal';
import { RequestCreate } from './RequestCreate';
import { RequestReject } from './RequestReject';
import { RequestEdit, getRequestTitle } from './RequestEdit';
import { Request } from '../request/Request';
import ScrollBar from '../../components/ScrollBar';
import { getStatus } from '../default/Status';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const oLocalStorage = new EncodedLocalStorage();
const RequestModal = oProps => {
  const { cTitle, nVersionId, onSuccess, hideAlert, oRequest, history, setAlert, onError, nQtyCharacters } = oProps;
  let nUserId = oLocalStorage.get('nUserId');
  let rcmpModal = null;
  let cModalTitle = cTitle;
  const cType = oProps.cType && oProps.cType !== undefined ? oProps.cType : oRequest ? oRequest.rqst_etype : '';

  if (oRequest && getStatus('pending', 'Request', oRequest) && nUserId == oRequest.user_nid_requester) {
    cModalTitle = intl.get('Request.update');
    if (cType == 'update') {
      rcmpModal = (
        <RequestCreate
          history={history}
          bIsNewRequest={false}
          nVersionId={oRequest.ver_nid}
          nRequestId={oRequest.rqst_nid}
          onSuccess={cMsg => onSuccess(cMsg)}
          closeModal={hideAlert}
          setAlert={setAlert}
          cType={cType}
          nUserDestinyId={oRequest.user_nid_destiny}
          cTitle={oRequest.rqst_ctitle}
          cJustification={oRequest.rqst_cjustification}
          nQtyCharacters={nQtyCharacters}
        />
      );
    } else {
      rcmpModal = (
        <RequestCreate
          history={history}
          bIsNewRequest={false}
          nVersionId={nVersionId}
          nRequestId={oRequest.rqst_nid}
          onSuccess={cMsg => onSuccess(cMsg)}
          closeModal={hideAlert}
          setAlert={setAlert}
          cType={cType}
          nUserDestinyId={oRequest.user_nid_destiny}
          cTitle={oRequest.rqst_ctitle}
          cJustification={oRequest.rqst_cjustification}
          nQtyCharacters={nQtyCharacters}
        />
      );
    }
  } else if (oRequest && getStatus('pending', 'Request', oRequest) && nUserId == oRequest.user_nid_destiny) {
    cModalTitle = getRequestTitle(oRequest.rqst_etype);
    rcmpModal = (
      <RequestEdit
        nRequestId={oRequest.rqst_nid}
        onSuccess={cMsg => onSuccess(cMsg)}
        closeModal={hideAlert}
        onError={onError}
        history={history}
        setAlert={setAlert}
        cType={cType}
      />
    );
  } else if (oRequest && getStatus('accepted', 'Request', oRequest)) {
    cModalTitle = getRequestTitle(oRequest.rqst_etype);
    rcmpModal = <Request nRequestId={oRequest.rqst_nid} closeModal={hideAlert} setAlert={setAlert} cType={cType} />;
  } else if (oRequest && getStatus('removed', 'Request', oRequest)) {
    cModalTitle = intl.get('Request.removed');
    rcmpModal = <Request nRequestId={oRequest.rqst_nid} closeModal={hideAlert} setAlert={setAlert} cType={cType} />;
  } else if (oRequest && getStatus('rejected', 'Request', oRequest) && nUserId == oRequest.user_nid_requester) {
    cModalTitle = `${intl.get('RequestReject.forward_request')} - ${oRequest.rqst_ctitle}`;
    rcmpModal = (
      <RequestReject
        nRequestId={oRequest.rqst_nid}
        onSuccess={cMsg => onSuccess(cMsg)}
        closeModal={hideAlert}
        history={history}
        setAlert={setAlert}
        cType={cType}
      />
    );
  } else if (oRequest && getStatus('rejected', 'Request', oRequest) && nUserId == oRequest.user_nid_destiny) {
    cModalTitle = getRequestTitle(oRequest.rqst_etype);
    rcmpModal = <Request nRequestId={oRequest.rqst_nid} closeModal={hideAlert} setAlert={setAlert} cType={cType} />;
  } else {
    rcmpModal = (
      <RequestCreate
        nVersionId={nVersionId}
        onError={onError}
        cType={cType}
        onSuccess={cMsg => onSuccess(cMsg)}
        closeModal={hideAlert}
        setAlert={setAlert}
        nQtyCharacters={nQtyCharacters}
      />
    );
  }

  return (
    <SweetAlert
      customClass="modal-edit md disabled-overflow scrollBar"
      title=""
      onConfirm={hideAlert}
      showConfirm={false}
    >
      <div className="head-modal">
        {cModalTitle}
        <span
          tabIndex="0"
          aria-labelledby="head-modal"
          onClick={hideAlert}
          className="close"
          role="button"
          onKeyPress={hideAlert}
        />
      </div>
      <ScrollBar>{rcmpModal}</ScrollBar>
    </SweetAlert>
  );
};

export default RequestModal;
