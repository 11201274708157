import React, { Component } from 'react';
import validator from 'validator';
import { Input, Button } from 'reactstrap';
import axios from 'axios';
import intl from 'react-intl-universal';
import { InputPassword } from '../../components/InputPassword';
import './styles/resetpassword.scss';
import { BrandImage } from '../../config/ApperanceContext';

class ResetPassword extends Component {
  constructor(oProps) {
    super(oProps);

    document.body.classList.add('w-bkg');

    this.state = {
      cToken: oProps.match.params.cToken,
      cEmail: '',
      cPassword: '',
      cPasswordConfirm: '',
      bHasAlert: false,
      aMsgErrors: [''],
      cAlertType: 'error',
      bIsSending: false,
      bValidPassword: false
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    const { cEmail, cToken, cPassword, cPasswordConfirm, bValidPassword } = this.state;
    if (!bValidPassword) {
      return false;
    }

    this.setState({ "bIsSending": true });

    axios
      .post('/reset-password/reset', {
        "cEmail": cEmail,
        "cToken": cToken,
        "cPassword": cPassword,
        "cPassword_confirmation": cPasswordConfirm
      })
      .then(() => {
        this.setState({
          "bHasAlert": true,
          "aMsgErrors": [intl.get('ResetPassword.senha_alterada')],
          "cAlertType": 'success'
        });
      })
      .catch((oError) => {
        const aErrorMessages = [];
        if (oError.response.status === 422) {
          Object.keys(oError.response.data.errors).forEach((cField) => {
            oError.response.data.errors[cField].map((evtResponse) => aErrorMessages.push(evtResponse));
          });
          // for (const field in error.response.data.errors) {
          //   error.response.data.errors[field].map(e => msgErrors.push(e));
          // }
        } else {
          aErrorMessages.push(intl.get('erro_500'));
        }
        this.setState({
          "bHasAlert": true,
          "aMsgErrors": aErrorMessages,
          "cAlertType": 'error',
          "bIsSending": false
        });
      });
  };

  validatePassword = evtPassword => {
    if (validator.isStrongPassword(evtPassword.target.value, {
      minLength: 6,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1
    })) {
      this.setState({
        bValidPassword: true
      });
    } else {
      this.setState({
        bValidPassword: false
      });
    }
  }

  render() {
    const { cEmail, cPassword, cPasswordConfirm, bIsSending, bHasAlert, cAlertType, aMsgErrors, bValidPassword } = this.state;
    const cPasswordClassName = (bValidPassword) ? 'span-strong' : 'span-weak';
    const cPasswordIntlName = (bValidPassword) ? 'Customers.password_strong' : 'Customers.password_weak';

    return (
      <section id="register">
        <div className="wrapper-login">
          <header>
            <div className="logo">
              <BrandImage cFileName="logo-text-134x75.png" bHasTitleAlt />
            </div>
            <h1>{intl.get('ResetPassword.resetar_senha')}</h1>
          </header>
          <main className="form reset-password">
            <form className="form-horizontal" onSubmit={this.handleSubmit}>
              <Input
                value={cEmail}
                onChange={this.handleChange}
                maxLength="250"
                className="form-control"
                placeholder={intl.get('cemail')}
                id="cEmail"
                required
                autoFocus=""
                name="cEmail"
                type="email"
              />
              <InputPassword
                value={cPassword}
                onChange={this.handleChange}
                onKeyUp={this.validatePassword} />
              <div className="input-feedback input-feedback-passwordStrength">
                {intl.get('Customers.password_strength')}: <span className={cPasswordClassName}>{intl.get(cPasswordIntlName)}</span>
              </div>
              <div className="input-feedback input-feedback-passwordInstructions">{intl.get('Customers.strong_password')}</div>

              <InputPassword
                placeholder={intl.get('cconfirmasenha')}
                name="cPasswordConfirm"
                value={cPasswordConfirm}
                onChange={this.handleChange}
              />
              <Button disabled={bIsSending} type="submit">
                {intl.get('enviar')}
              </Button>

              <a href="/login" className="btn alternate" style={{ float: 'left', textAlign: 'center' }}>
                {intl.get('voltar')}
              </a>

              {bHasAlert && (
                <div className={`alert ${cAlertType}`}>
                  {aMsgErrors.map((cMsg) => (
                    <p key={Math.random()}>{cMsg}</p>
                  ))}
                </div>
              )}
            </form>
          </main>
        </div>
      </section>
    );
  }
}

export default ResetPassword;
