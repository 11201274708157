import React, { Component } from 'react';
import validator from 'validator';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';
import intl from 'react-intl-universal';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import { set } from 'lodash';
import AltCheckbox from '../../components/AltCheckbox';
import { InputPassword } from '../../components/InputPassword';
import Axios from '../../config/Axios';
import url from '../../config/Url';
import { can, canAtLeast } from '../../config/Permissions';
import Breadcrumb from '../../components/Breadcrumbs';
import Page from '../default-v2/Page';
import PageClean from '../default-v2/PageClean';
import { updateUser, uploadImage } from '../../actions/ActionUsers';
import CardHeader from '../default-v2/CardHeader';
import './styles/user.scss';
import userImg from '../../assets/img/users.svg';
import OperationalPanelImg from '../../assets/img/operationalPanelIcon.svg';
import auditImg from '../../assets/img/auditIcon.svg';
import UserConfigIcon from '../../assets/icons/UserConfigIcon';
import DocumentIcon from '../../assets/icons/DocumentIcon';
import QualityIcon from '../../assets/icons/QualityIcon';
import warningIcon from '../../assets/img/264-warning-blue.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const oPageComponents = {
  page: Page,
  pageClean: PageClean
};

const oLocalStorage = new EncodedLocalStorage();

class User extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      bPage: true,
      bIsSending: false,
      aTeams: [{}],
      aSelectedTeams: [],
      cImagePreviewUrl: '',
      oUser: {
        user_cpasswordconfirm: ''
      },
      oUserChange: {
        user_cemail: '',
        user_cpassword: ''
      },
      aPages: [
        {
          cName: 'UserIndex.titulo',
          cLink: '/user'
        },
        {
          cName: 'User.titulo',
          cLink: undefined
        }
      ],
      cUserEmail: '',
      bValidPassword: false,
      aAllowedAccessTime: [[], [], [], [], [], [], []],
      bIsActive: true,
      bChangedAvatar: false,
      bIsSingleResponsible: null,
      aModuleResponsible:[]
    };
  }

  componentDidMount = () => {
    let nMountId = '';
    const { history } = this.props;

    Axios.get('team').then(oResponse => {
      const aTeams = [];

      Object.keys(oResponse.data).forEach(cKey => {
        aTeams.push({ label: oResponse.data[cKey].team_cname, value: Number(oResponse.data[cKey].team_nid) });
      });
      this.setState({
        aTeams
      });
    });

    const { nId, match, setAlert } = this.props;

    if (nId) {
      nMountId = nId;
      this.setState({
        bPage: false
      });
    } else {
      nMountId = match.params.nId;
    }

    this.getResponsibilities(nMountId);

    Axios.get(`/user/${nMountId}`)
      .then(oRes => {
        const oUser = oRes.data;
        let cImagePreviewUrl = '';
        const aSelectedTeams = [];
        const aNewAllowedAccessTime = [[], [], [], [], [], [], []];
        let nIndex = 0;
        this.setState({
          aSelectedTeams
        });

        Object.keys(oUser.myTeamsName).forEach(cKey => {
          aSelectedTeams.push({ label: oUser.myTeamsName[cKey], value: Number(cKey) });
        });

        if (oUser.user_cavatar !== null) {
          cImagePreviewUrl = oUser.user_cavatar;
        }

        Object.keys(oUser.allowed_access_time).forEach(cKey => {
          if (oUser.allowed_access_time[cKey].uaat_edayname === 'Sunday') {
            nIndex = 0;
          }
          if (oUser.allowed_access_time[cKey].uaat_edayname === 'Monday') {
            nIndex = 1;
          }
          if (oUser.allowed_access_time[cKey].uaat_edayname === 'Tuesday') {
            nIndex = 2;
          }
          if (oUser.allowed_access_time[cKey].uaat_edayname === 'Wednesday') {
            nIndex = 3;
          }
          if (oUser.allowed_access_time[cKey].uaat_edayname === 'Thursday') {
            nIndex = 4;
          }
          if (oUser.allowed_access_time[cKey].uaat_edayname === 'Friday') {
            nIndex = 5;
          }
          if (oUser.allowed_access_time[cKey].uaat_edayname === 'Saturday') {
            nIndex = 6;
          }

          aNewAllowedAccessTime[nIndex].push({
            cBeginTime: oUser.allowed_access_time[cKey].uaat_tbegintime,
            cEndTime: oUser.allowed_access_time[cKey].uaat_tendtime
          });
        });

        this.setState({
          bLoading: false,
          oUser: oRes.data,
          fSelectedFile: oUser.user_cavatar,
          cImagePreviewUrl,
          aSelectedTeams,
          cUserEmail: oRes.data.user_cemail,
          aAllowedAccessTime: aNewAllowedAccessTime,
          bIsActive: oRes.data.oCustomerUser.cu_bisactive
        });
      })
      .catch(oError => {
        if (oError.response.status === 403) {
          history.push({
            pathname: '/user',
            state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
          });
        } else {
          this.setState({ bLoading: false });
          setAlert('error', oError.aMsgErrors);
        }
      });
  };

  getResponsibilities = async (nUserId) => {
    try {
      const oResponse = await Axios.get(`user/is-single-responsibile/${nUserId}`);
      this.setState({
        bIsSingleResponsible: !!oResponse.data.isSingleResponsible,
        aModuleResponsible: oResponse.data.aModuleResponsible || {},
      });
    } catch (oError) {
      const { setAlert } = this.props;
      setAlert('error', oError.aMsgErrors);
    }
  };


  handleChangeCustom = elSelectedOption => {
    const { oUser } = this.state;
    const oCustomUser = { ...oUser };
    const oMyTeamsName = {};

    Object.keys(elSelectedOption).forEach(cKey => {
      oMyTeamsName[elSelectedOption[cKey].value] = elSelectedOption[cKey].label;
    });
    oCustomUser.myTeamsName = oMyTeamsName;

    this.setState({
      oUser: oCustomUser
    });
  };

  saveConfig = (nId, oData) => {
    if (Number(oLocalStorage.get('nUserId')) === Number(nId)) {
      const { cLanguage, cTimezone } = oData;

      const cLanguageConfig = oLocalStorage.get('cLanguage');

      oLocalStorage.set('cTimezone', cTimezone);
      oLocalStorage.set('cLanguage', cLanguage);
    }
  };

  inputChangeHandler = evtChange => {
    if (!evtChange || !evtChange.target) {
      return;
    }

    const { oUser } = this.state;

    if (evtChange.target.name === 'bIsActive') {
      this.setState({
        bIsActive: evtChange.target.checked
      });
      return;
    }

    if (evtChange.target.type === 'checkbox') {
      oUser[evtChange.target.name] = evtChange.target.checked;
      this.setState({
        oUser
      });
    } else if (evtChange.target.value !== null) {
      oUser[evtChange.target.name] = evtChange.target.value;
      this.setState({
        oUser
      });
    }
  };

  inputChangeUser = evt => {
    const { oUserChange } = this.state;
    oUserChange[evt.target.name] = evt.target.value;
    this.setState({
      oUserChange
    });
  };

  onSubmitUser = evt => {
    evt.preventDefault();

    const { oUser, bValidPassword } = this.state;
    const { setAlert } = this.props;

    if (oUser.user_cpassword !== oUser.user_cpasswordconfirm && oUser.user_cpasswordconfirm !== undefined) {
      setAlert('error', intl.get('User.mensagem_senha_diferente'));
    } else if (oUser.user_cpassword !== undefined && oUser.user_cpassword.length > 0 && bValidPassword !== true) {
      setAlert('error', intl.get('Customers.password_strength_error'));
    } else {
      this.updateUser();
    }
  };

  redirectUser = (history, oMsg) => {
    if (history.location.pathname === '/my-account/my-profile') {
      window.location.reload();
      history.push({
        state: oMsg
      });
    } else {
      history.push({
        pathname: '/user',
        state: oMsg
      });
      window.location.reload();
    }
  };

  checkEmailChange = (bHasEmailUpdated, history) => {
    if (bHasEmailUpdated) {
      this.redirectUser(history, { aAlertMessages: { cType: 'success', cMsg: intl.get('User.change_email_message') } });
    } else {
      this.redirectUser(history, {
        aAlertMessages: { cType: 'success', cMsg: intl.get('User.mensagem_gravar_sucesso') }
      });
    }
  };

  checkEmailChangeError = (bHasEmailUpdated, history, setAlert, aMsgErrors) => {
    if (bHasEmailUpdated) {
      setAlert('error', `${intl.get('User.change_email_avatar_message')}${aMsgErrors}`);
    } else {
      setAlert('error', `${intl.get('User.mensagem_gravar_entretanto')}${aMsgErrors}`);
    }
  };

  updateUser = () => {
    const { oUser, fSelectedFile, cUserEmail, aAllowedAccessTime, bIsActive, bChangedAvatar } = this.state;
    const { history, setAlert } = this.props;
    if (Object.keys(oUser.myTeamsName).length) {
      this.setState({
        bIsSending: true
      });

      const bHasEmailUpdated = cUserEmail !== oUser.user_cemail;

      const aAllowedKeys = [
        "user_cname",
        "user_bisadmin",
        "user_cemail",
        "user_ccellphone",
        "user_ctimezone",
        "user_clang",
        "user_cpassword",
        "user_ctokenremember",
        "user_cdocusign_account_id",
        "myTeamsName",
      ];
      const oUserSending = Object.keys(oUser)
        .filter((cKey) => aAllowedKeys.includes(cKey))
        .reduce((oAcc, cKey) => {
          oAcc[cKey] = oUser[cKey];
          return oAcc;
        }, {});
      oUserSending.user_bisactive = bIsActive;
      oUserSending.user_bisadmin = !!oUser.bIsAdmin;

      if (!bChangedAvatar) {
        delete oUserSending.user_cavatar;
      }

      updateUser(oUser.user_nid, oUserSending, aAllowedAccessTime)
        .then(oResponse => {
          if (fSelectedFile && fSelectedFile !== oUser.user_cavatar && bChangedAvatar) {
            this.uploadImage()
              .then(oResponseAvatar => {
                this.checkEmailChange(bHasEmailUpdated, history);
              })
              .catch(oError => {
                this.checkEmailChangeError(bHasEmailUpdated, history, setAlert, oError.aMsgErrors);
              });
          } else {
            this.checkEmailChange(bHasEmailUpdated, history);
          }

          this.saveConfig(oUser.user_nid, oResponse.data);
          this.setState({
            bIsSending: false
          });
        })
        .catch(oError => {
          setAlert('error', oError.aMsgErrors);
          if (oError.aMsgErrors.includes('E-mail já existe')) {
            this.setState({
              rcmpAlert: (
                <SweetAlert
                  title="Alteração de e-mail"
                  showCancel
                  confirmBtnText={intl.get('confirmar')}
                  cancelBtnText={intl.get('cancelar')}
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.hideAlert();
                    this.updateEmail();
                  }}
                  onCancel={() => {
                    this.hideAlert();
                  }}
                >
                  <p>
                    O e-mail que você digitou já está em uso! Essa conta será substituída pela conta do e-mail digitado.
                  </p>
                  <p style={{ marginBottom: '25px' }}>
                    Se deseja continuar, digite a senha vinculada a ele, por gentileza.
                  </p>
                  <main className="form">
                    <form className="form-horizontal">
                      <Input type="text" maxLength="250" required disabled value={oUser.user_cemail || ''} />
                      <InputPassword
                        name="user_cpassword"
                        id="user_cpassword"
                        required
                        onChange={this.inputChangeUser}
                      />
                    </form>
                  </main>
                </SweetAlert>
              )
            });
          }
          this.setState({
            bIsSending: false
          });
        });
    } else {
      setAlert('error', intl.get('User.mensagem_gravar_selecionada'));
    }
  };

  updateEmail = () => {
    const { oUserChange, oUser } = this.state;
    const { history, match, setAlert } = this.props;

    const { nId } = match.params;
    Axios.put(`/user/vinculate-user/${nId}`, {
      user_cemail: oUser.user_cemail,
      user_cpassword: oUserChange.user_cpassword
    })
      .then(oRes => {
        setAlert('success', oRes.data);
        history.push({
          pathname: '/user'
        });
      })
      .catch(oError => setAlert('error', oError.aMsgErrors));
  };

  fileChangedHandler = evt => {
    evt.preventDefault();

    const frChanged = new FileReader();
    const fFile = evt.target.files[0];

    frChanged.onloadend = () => {
      const { oUser } = this.state;
      oUser.user_cavatar = fFile.name;
      if (fFile.type === 'image/png' || fFile.type === 'image/jpg' || fFile.type === 'image/jpeg') {
        this.setState({
          fSelectedFile: fFile,
          cImagePreviewUrl: frChanged.result,
          oUser,
          bChangedAvatar: true
        });
      } else {
        this.setState({
          rcmpAlert: (
            <SweetAlert
              danger
              confirmBtnText="Ok"
              confirmBtnBsStyle="warning"
              title={intl.get('User.legenda_imagem')}
              onConfirm={this.hideAlert}
              onCancel={this.hideAlert}
            >
              {intl.get('User.image_extension_message')}
            </SweetAlert>
          )
        });
      }
    };
    if (fFile) frChanged.readAsDataURL(fFile);
  };

  triggerInputFile = () => {
    const elLink = document.getElementById('user_cavatar');
    elLink.click();
  };

  removeImage = () => {
    const { oUser } = this.state;
    oUser.cAvatar = '';

    this.setState({
      fSelectedFile: '',
      cImagePreviewUrl: '',
      oUser
    });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null,
      cWarningModalType: null,
    });
  };

  uploadImage = () => {
    const fdataImage = new FormData();
    const { fSelectedFile, oUser } = this.state;
    const { setAlert, history } = this.props;
    fdataImage.append('user_cavatar', fSelectedFile, fSelectedFile.name);
    fdataImage.append('user_nid', oUser.user_nid);
    return uploadImage(fdataImage);
  };

  validatePassword = evtPassword => {
    if (
      validator.isStrongPassword(evtPassword.target.value, {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1
      })
    ) {
      this.setState({
        bValidPassword: true
      });
    } else {
      this.setState({
        bValidPassword: false
      });
    }
  };

  handleChangeTime = (evtChange, cField, nDayIndex, nTimeIndex) => {
    evtChange.preventDefault();
    const { aAllowedAccessTime } = this.state;

    aAllowedAccessTime[nDayIndex][nTimeIndex][cField] = evtChange.target.value;

    this.setState({
      aAllowedAccessTime
    });
  };

  handleAddTime = (evtClick, nDayIndex) => {
    evtClick.preventDefault();
    const { aAllowedAccessTime } = this.state;

    aAllowedAccessTime[nDayIndex].push({
      cBeginTime: '00:00',
      cEndTime: '00:00'
    });

    this.setState({
      aAllowedAccessTime
    });
  };

  handleRemoveTime = (evtClick, nDayIndex, nTimeIndex) => {
    evtClick.preventDefault();
    const { aAllowedAccessTime } = this.state;

    aAllowedAccessTime[nDayIndex].splice(nTimeIndex, 1);

    this.setState({
      aAllowedAccessTime
    });
  };

  getTimesByDay = (aAllowedAccessTime, cDayName, nDayIndex) => {

    const bCanChangeTime = canAtLeast(['create-edit-user', 'admin']);

    return (
      <>
        <div className="config-times">
          <div className="menu">
            <span>{cDayName}</span>
            {bCanChangeTime && (
              <button type="button" onClick={evtClick => this.handleAddTime(evtClick, nDayIndex)} >
                {intl.get('User.time')}
              </button>
            )}
          </div>

          <div className="times">
            {aAllowedAccessTime.map((oTime, nIndex) => (
              <div className="time" key={nIndex}>
                <div className="from">
                  <label>{intl.get('User.from')}</label>
                  <input
                    type="time"
                    id={`${cDayName}-begin-time-${nIndex}`}
                    name={`${cDayName}-begin-time-${nIndex}`}
                    value={oTime.cBeginTime}
                    required
                    onChange={evtChange => this.handleChangeTime(evtChange, 'cBeginTime', nDayIndex, nIndex)}
                    readOnly={!bCanChangeTime}
                    disabled={!bCanChangeTime}
                  />
                </div>
                <div className="to">
                  <label>{intl.get('User.to')}</label>
                  <input
                    type="time"
                    id={`${cDayName}-end-time-${nIndex}`}
                    name={`${cDayName}-end-time-${nIndex}`}
                    value={oTime.cEndTime}
                    required
                    onChange={evtChange => this.handleChangeTime(evtChange, 'cEndTime', nDayIndex, nIndex)}
                    readOnly={!bCanChangeTime}
                    disabled={!bCanChangeTime}
                  />
                </div>
                {bCanChangeTime && (
                  <button
                    aria-labelledby="buttons"
                    className="remove"
                    type="button"
                    onClick={evtClick => {
                      this.handleRemoveTime(evtClick, nDayIndex, nIndex);
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  renderConfigAllowedTime = () => {
    const { aAllowedAccessTime } = this.state;

    return (
      <>
        {this.getTimesByDay(aAllowedAccessTime[0], intl.get('User.sunday'), 0)}
        {this.getTimesByDay(aAllowedAccessTime[1], intl.get('User.monday'), 1)}
        {this.getTimesByDay(aAllowedAccessTime[2], intl.get('User.tuesday'), 2)}
        {this.getTimesByDay(aAllowedAccessTime[3], intl.get('User.wednesday'), 3)}
        {this.getTimesByDay(aAllowedAccessTime[4], intl.get('User.thursday'), 4)}
        {this.getTimesByDay(aAllowedAccessTime[5], intl.get('User.friday'), 5)}
        {this.getTimesByDay(aAllowedAccessTime[6], intl.get('User.saturday'), 6)}
      </>
    );
  };

  redirectToMigration = route => {
    window.history.pushState({ page: 'migrate-document' }, 'migrate document', route);

    window.location.reload(true);
  };

  renderInputs = () => {
    const { fSelectedFile, oUser, cImagePreviewUrl, aTeams, aSelectedTeams, bIsActive } = this.state;

    if (!oUser.user_nid) {
      return null;
    }

    return (
      <>
        <fieldset>
          <div className="customer-head">
            <legend> {intl.get('User.legenda_informacoes')}</legend>
          </div>

          <div className="user-info">
            <div className="avatar">
              <div>
                <span
                  href="user"
                  className={`avatar-placeholder ${fSelectedFile || oUser.user_cavatar ? 'disabled-avatar' : ''}`}
                  data-field="[name=user_cavatar]"
                >
                  {intl.get('MyProfile.profile_photo')}
                  <img
                    style={{ visibility: cImagePreviewUrl ? 'visible' : 'hidden' }}
                    src={cImagePreviewUrl}
                    alt=""
                    id="avatar-preview"
                  />
                </span>

                <span
                  className="icon"
                  role="button"
                  tabIndex="0"
                  onKeyDown={this.handleKeyDown}
                  onClick={this.triggerInputFile}
                  href="user"
                  data-field="[name=user_cavatar]"
                >
                  <i className="icon-camera" />
                </span>

                <Input
                  onChange={this.fileChangedHandler}
                  type="file"
                  name="user_cavatar"
                  id="user_cavatar"
                  className="hidden avatar-upload"
                  data-preview="avatar-placeholder img"
                  accept=".jpg, .jpeg, .png"
                  hidden
                />
              </div>
            </div>

            <div className="user-fields">
              <label className="colspan-48p">
                {intl.get('User.cnome')} <span className="required">*</span>
                <Input
                  type="text"
                  name="user_cname"
                  id="user_cname"
                  maxLength="250"
                  required
                  onChange={evt => this.inputChangeHandler.call(this, evt)}
                  value={oUser.user_cname || ''}
                />
              </label>

              <label className="colspan-48p">
                {intl.get('cemail')}
                <span className="required">*</span>
                <Input
                  type="text"
                  name="user_cemail"
                  id="user_cemail"
                  maxLength="250"
                  required
                  onChange={evt => this.inputChangeHandler.call(this, evt)}
                  value={oUser.user_cemail || ''}
                />
              </label>

              {this.renderAccessData()}

              <label className="colspan-31p">
                {intl.get('Customers.ccelular')}

                <MaskedInput
                  mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  onChange={evt => this.inputChangeHandler.call(this, evt)}
                  render={(cRef, oProps) => (
                    <Input
                      innerRef={cRef}
                      {...oProps} // não sei todos os elementos daqui
                      value={oUser.user_ccellphone || ''}
                      type="text"
                      name="user_ccellphone"
                      id="user_ccellphone"
                      className="mask-phone"
                      placeholder={intl.get('Customers.ccelular')}
                    />
                  )}
                />
              </label>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <div className="customer-head">
            <legend>{intl.get('User.legenda_localizacao')}</legend>
          </div>

          <div className="user-config">
            <label className="colspan-48p">
              {intl.get('User.ctz')}
              <Input
                type="select"
                name="user_ctimezone"
                id="user_ctimezone"
                onChange={evt => this.inputChangeHandler.call(this, evt)}
                value={oUser.user_ctimezone || 'America/Sao_Paulo'}
              >
                <option value="America/Araguaina">America/Araguaina</option>
                <option value="America/Bahia">America/Bahia</option>
                <option value="America/Belem">America/Belem</option>
                <option value="America/Boa_Vista">America/Boa Vista</option>
                <option value="America/Campo_Grande">America/Campo Grande</option>
                <option value="America/Cuiaba">America/Cuiaba</option>
                <option value="America/Eirunepe">America/Eirunepe</option>
                <option value="America/Fortaleza">America/Fortaleza</option>
                <option value="America/Maceio">America/Maceio</option>
                <option value="America/Manaus">America/Manaus</option>
                <option value="America/Noronha">America/Noronha</option>
                <option value="America/Porto_Velho">America/Porto Velho</option>
                <option value="America/Recife">America/Recife</option>
                <option value="America/Rio_Branco">America/Rio Branco</option>
                <option value="America/Santarem">America/Santarem</option>
                <option value="America/Sao_Paulo">America/São Paulo</option>
              </Input>
            </label>

            <label className="colspan-48p">
              {intl.get('User.cidioma')}
              <Input
                type="select"
                name="user_clang"
                id="user_clang"
                onChange={evt => this.inputChangeHandler.call(this, evt)}
                value={oUser.user_clang || 'pt-br'}
              >
                <option value="pt-br">{intl.get('User.cidioma_pt-br')}</option>
                <option value="en">{intl.get('User.cidioma_en')}</option>
              </Input>
            </label>

            {(can('admin') || can('create-edit-team')) && (
              <>
                <label className="split1">
                  {intl.get('User.equipes')} <span className="required">*</span>
                  {aSelectedTeams.length !== 0 ? (
                    <>
                      <Select
                        className="selectCustom"
                        classNamePrefix="react-select"
                        noOptionsMessage={() => intl.get('User.equipe_nao_encontrada')}
                        isMulti
                        required
                        placeholder={intl.get('User.equipes')}
                        defaultValue={aSelectedTeams}
                        options={aTeams}
                        onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption)}
                      />
                    </>
                  ) : (
                    <Select
                      className="selectCustom"
                      classNamePrefix="react-select"
                      onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption)}
                      noOptionsMessage={() => intl.get('User.equipe_nao_encontrada')}
                      isMulti
                      required
                      placeholder={intl.get('User.equipes')}
                      options={aTeams}
                    />
                  )}
                </label>
              </>
            )}

            {can('admin') && (
              <AltCheckbox
                name="bIsAdmin"
                id="bIsAdmin"
                label={intl.get('User.badministrador')}
                onChange={evtChange => {
                  const nUserId = Number(oLocalStorage.get('nUserId'));
                  if (evtChange.target.checked === false && oUser.user_nid === nUserId) {
                    return this.setState({ cWarningModalType: 'bIsAdmin' })
                  }
                  this.inputChangeHandler(evtChange);
                }}
                checked={!!oUser.bIsAdmin}
              />
            )}
            <AltCheckbox
              name="bIsActive"
              id="bIsActive"
              label={intl.get('User.bativo')}
              onChange={evt => {
                if (evt.target.checked === false) {
                  return this.setState({ cWarningModalType: 'bIsActive' });
                }
                this.inputChangeHandler(evt);
              }}
              checked={!!bIsActive}
            />
          </div>
        </fieldset>

        {can('admin') && (
          <>
            <div className="fieldset-container">
              <fieldset className="fieldset">
                {' '}
                <div className="customer-head">
                  <legend>
                    <QualityIcon className="migrate-icon-title" fill="#737D86" />
                    {intl.get('QualityPanelIndex.titulo')}
                  </legend>
                </div>
                <div className="user-config">
                  <UserConfigIcon className="migrate-icon" />
                  <span
                    onClick={() => this.redirectToMigration(`/quality-panel/migrate-responsibility/${oUser.user_nid}`)}
                    className="bold custom-hyperlink"
                  >
                    {intl.get('User.migrate_responsibilities')}
                  </span>
                </div>
              </fieldset>

              <fieldset className="fieldset">
                {' '}
                <div className="customer-head">
                  <legend>
                    {' '}
                    <img src={auditImg} alt="Audit" className="migrate-icon-title" />
                    {intl.get('PermissionGroup.audit-management')}
                  </legend>
                </div>
                <div className="user-config">
                  <UserConfigIcon className="migrate-icon" />
                  <span
                    onClick={() => this.redirectToMigration(`/audits/migrate-responsibility/${oUser.user_nid}`)}
                    className="bold custom-hyperlink"
                  >
                    {intl.get('User.migrate_responsibilities')}
                  </span>
                </div>
              </fieldset>

              <fieldset className="fieldset">
                {' '}
                <div className="customer-head">
                  <legend>
                    <img src={OperationalPanelImg} alt="Operational Panel" className="migrate-icon-title" />
                    {intl.get('PermissionGroup.operational-panel')}
                  </legend>
                </div>
                <div className="user-config">
                  <UserConfigIcon className="migrate-icon" />
                  <span
                    onClick={() =>
                      this.redirectToMigration(`/operational-panel/migrate-responsibility/${oUser.user_nid}`)
                    }
                    className="bold custom-hyperlink"
                  >
                    {intl.get('User.migrate_responsibilities')}
                  </span>
                </div>
              </fieldset>

              <fieldset className="fieldset">
                <div className="customer-head">
                  <legend>
                    {' '}
                    <DocumentIcon className="migrate-icon-title" fill="#737D86" />
                    {intl.get('modulo_documentos')}
                  </legend>
                </div>

                <div className="user-config">
                  <UserConfigIcon className="migrate-icon" />
                  <span
                    onClick={() => this.redirectToMigration(`/document/migrate-responsibility/${oUser.user_nid}`)}
                    className="bold custom-hyperlink"
                  >
                    {intl.get('User.migrate_responsibilities')}
                  </span>
                </div>
              </fieldset>
            </div>
          </>
        )}

        {!oUser.bIsAdmin && (
          <fieldset>
            <div className="customer-head">
              <legend>{intl.get('User.title_config_allowed_time')}</legend>
            </div>

            <div className="user-config-times">
              <div className="config-message">
                <p>{intl.get('User.config_mesage')}</p>
              </div>
              <div className="config-body">{this.renderConfigAllowedTime()}</div>
            </div>
          </fieldset>
        )}
      </>
    );
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpCardHeader = <CardHeader history={history} />;
    return rcmpCardHeader;
  };

  renderAccessData() {
    const { oUser, bValidPassword } = this.state;
    const cPasswordClassName = bValidPassword ? 'span-strong' : 'span-weak';
    const cPasswordIntlName = bValidPassword ? 'Customers.password_strong' : 'Customers.password_weak';

    if (oUser && oUser.user_nid === Number(oLocalStorage.get('nUserId'))) {
      return (
        <>
          <label className="colspan-31p password">
            {intl.get('csenha')} <span className="required">*</span>
            <InputPassword
              name="user_cpassword"
              id="user_cpassword"
              data-lpignore="true"
              required={false}
              onChange={this.inputChangeHandler}
              onKeyUp={this.validatePassword}
              value={oUser.user_cpassword || ''}
              autoComplete="new-password"
            />
            <div className="input-feedback input-feedback-passwordStrength">
              {intl.get('Customers.password_strength')}:{' '}
              <span className={cPasswordClassName}>{intl.get(cPasswordIntlName)}</span>
            </div>
            <div className="input-feedback input-feedback-passwordInstructions">
              {intl.get('Customers.strong_password')}
            </div>
          </label>
          <label className="colspan-31p password">
            {intl.get('cconfirmasenha')} <span className="required">*</span>
            <InputPassword
              name="user_cpasswordconfirm"
              id="user_cpasswordconfirm"
              required={false}
              onChange={this.inputChangeHandler}
              value={oUser.user_cpasswordconfirm || ''}
              autoComplete="new-password"
            />
          </label>
        </>
      );
    }
    return null;
  }

  renderModal() {
    const { rcmpAlert, cWarningModalType, bIsSingleResponsible, aModuleResponsible } = this.state;

    if (rcmpAlert) return rcmpAlert;

    let cTitle, cMessage, fnOnConfirm;
    switch (cWarningModalType) {
      case 'bIsActive':
        cTitle = intl.get('User.bativo');
        cMessage = intl.get('User.user_deactivation_message');
        // só abre esse modal quando estiver desativando o usuário
        fnOnConfirm = () => {
          this.inputChangeHandler({ target: { name: 'bIsActive', checked: false }});
          this.hideAlert();
        }
        break;
      case 'bIsAdmin':
        cTitle = intl.get('User.badministrador');
        cMessage = intl.get('User.adm_user_change_message');
        // só abre esse modal quando estiver removendo admin
        fnOnConfirm = () => {
          this.inputChangeHandler({ target: { name: 'bIsAdmin', checked: false }});
          this.hideAlert();
        }
        break;
      default:
        return null;
    }

    return (
      <WarningModal
        bShow
        cTitle={cTitle}
        cMessage={cMessage}
        cConfirmType={cWarningModalType}
        bIsSingleResponsible={bIsSingleResponsible}
        aModuleResponsible={aModuleResponsible}
        fnOnConfirm={fnOnConfirm}
        fnOnClose={this.hideAlert}
      />
    );
  }

  renderForm = () => {
    const { oUser, bIsSending } = this.state;
    const { history } = this.props;

    return (
      <>
        <form autoComplete="off" onSubmit={this.onSubmitUser}>
          {this.renderInputs()}
          <div className="button-stack">
            <Button type="button" className="btn-cancel" onClick={history.goBack} disabled={bIsSending}>
              {intl.get('cancelar')}
            </Button>

            {oUser.user_nid && (
              <Button disabled={bIsSending} type="submit">
                {intl.get('salvar')}
              </Button>
            )}
          </div>
        </form>
      </>
    );
  };

  render() {
    const { aPages, bLoading } = this.state;
    const { bIsCleanPage, getAlert } = this.props;

    let cComponentName = 'page';
    if (bIsCleanPage) {
      cComponentName = 'pageClean';
    }

    const rcmpCardHeader = this.generateCardHeader();

    if (cComponentName === 'pageClean') {
      return <PageClean loading={bLoading ? 1 : 0}>{this.renderForm()}</PageClean>;
    }

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={<Breadcrumb aPages={aPages} />}
        cTitle={intl.get('User.titulo')}
        cImage={userImg}
        rcmpCardHeader={rcmpCardHeader}
        cCurrentSideMenuOption={intl.get('Nav.users')}
      >
        {getAlert()}
        {this.renderModal()}
        {this.renderForm()}
      </Page>
    );
  }
}

User.defaultProps = {
  bIsCleanPage: false
};


class WarningModal extends React.Component {
  constructor(props) {
    super(props);
  }

  handleConfirm = () => {
    const { fnOnConfirm } = this.props;
    fnOnConfirm();
  }

  handleClose = () => {
    const { fnOnClose } = this.props;
    fnOnClose();
  }

  render() {
    const { bShow, cConfirmType, bIsSingleResponsible, cTitle, cMessage,aModuleResponsible } = this.props;
    if (!bShow) return null;
    const bIsLoading = cConfirmType === 'bIsActive' && bIsSingleResponsible === null;
    const bShowConfirm = cConfirmType !== 'bIsActive' || bIsSingleResponsible === false;


    const rcmpModuleStatus = aModuleResponsible ? (
      <div className="module-status">
        <div><span style={{ fontWeight: '700' }}>{intl.get('User.migrate_module')}:</span></div>
        <div>
          {[
            aModuleResponsible.bNorm &&  intl.get('User.migrate_module_norm'),
            aModuleResponsible.bPainelOperational &&  intl.get('User.migrate_mudule_operational'),
            aModuleResponsible.bAuditor && intl.get('User.migrate_module_audit'),
            aModuleResponsible.bDocuments &&  intl.get('User.migrate_module_document')
          ]
            .filter(Boolean)
            .join(', ')}.
        </div>
      </div>
    ) : null;


    const rcmpMessage = (cConfirmType === 'bIsActive' && bIsSingleResponsible)
    ? (
      <div className="docusign-modal-text align-center">
        <span style={{ fontWeight: '700' }}>{intl.get('User.user_is_single_responsible')}</span>
        {' '}
        {intl.get('User.migrate_the_responsiblities')}
        {rcmpModuleStatus}
      </div>
    ) : cMessage;

    return (
      <SweetAlert
        warning
        showCancel
        showConfirm={bShowConfirm}
        confirmBtnText={intl.get('confirmar')}
        cancelBtnText={intl.get('cancelar')}
        cancelBtnBsStyle="warning"
        title={cTitle}
        onConfirm={this.handleConfirm}
        onCancel={this.handleClose}
      >
        {bIsLoading ? (
          <div className="spinner" style={{
            height: '40px',
            width: '40px',
            animationDuration: '0.75s'
        }}></div>
        ) : rcmpMessage}
      </SweetAlert>
    );
  }
}

WarningModal.propTypes = {
  bShow: PropTypes.bool,
  cTitle: PropTypes.string,
  cMessage: PropTypes.string,
  cConfirmType: PropTypes.string,
  bIsSingleResponsible: PropTypes.bool,
  aModuleResponsible: PropTypes.objectOf(PropTypes.bool),
  fnOnConfirm: PropTypes.func,
  fnOnClose: PropTypes.func
};

export default User;
