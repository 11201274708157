import axios from 'axios';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import { BrandImage } from '../../config/ApperanceContext';

class ResetPasswordRequest extends Component {
  constructor(oProps) {
    super(oProps);

    document.body.classList.add('w-bkg');

    this.state = {
      "cEmail": '',
      "bHasAlert": false,
      "bIsSending": false,
      "cMessage": ''
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    const { cEmail } = this.state;
    this.setState({ "bIsSending": true });

    axios
      .post('/reset-password/email', {
        "cEmail": cEmail
      })
      .then(() => {
        this.setState({
          "bHasAlert": true,
          "cMessage": intl.get('ResetPasswordRequest.link_email'),
          "bIsSending": false
        });
      })
      .catch(() => {
        this.setState({
          "bHasAlert": true,
          "cMessage": intl.get('ResetPasswordRequest.erro_verifique_email'),
          "bIsSending": false
        });
      });
  };

  render() {
    const { cEmail, bIsSending, bHasAlert, cMessage } = this.state;
    return (
      <section id="register">
        <div className="wrapper-login">
          <header>
            <div className="logo">
              <BrandImage cFileName="logo-text-134x75.png" bHasTitleAlt />
            </div>
            <h1>{intl.get('ResetPassword.resetar_senha')}</h1>
          </header>
          <main className="form">
            <form className="form-horizontal" onSubmit={this.handleSubmit}>
              <Input
                value={cEmail}
                onChange={this.handleChange}
                maxLength="250"
                className="form-control"
                placeholder={intl.get('cemail')}
                id="cEmail"
                required
                autoFocus=""
                name="cEmail"
                type="email"
              />
              <Button disabled={bIsSending} type="submit">
                {intl.get('enviar')}
              </Button>

              <a href="/login" className="btn alternate" style={{ float: 'left', textAlign: 'center' }}>
                {intl.get('voltar')}
              </a>
              {bHasAlert && <div className="alert">{cMessage}</div>}
            </form>
          </main>
        </div>
      </section>
    );
  }
}

export default ResetPasswordRequest;
