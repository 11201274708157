import moment from 'moment';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import intl from 'react-intl-universal';
import $ from 'jquery';

import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMoveMutable from 'array-move';

import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import AltCheckbox from '../../components/AltCheckbox';
import Button from '../../components/Button';
import { getNorm, getRequisites } from '../../actions/ActionRequisite';
import { Alert } from '../../components/Alert';
import Breadcrumb from '../../components/Breadcrumbs';
import { OptionMenu } from '../../components/OptionMenu';
import Axios from '../../config/Axios';
import Page from '../default-v2/Page';
import Norm from './Norm';
import Requisite from './Requisite';
import RequisiteShow from './RequisiteShow';
import { can, checkModule } from '../../config/Permissions';
import ScrollBar from '../../components/ScrollBar';

import Filter from './components/Filter';
import CardHeader from '../default-v2/CardHeader';

import './styles/requisiteindex.scss';
import qualityImg from '../../assets/img/quality.svg';
import { oLocalStorage } from '../../config/EncodedLocalStorage';

const DragHandle = sortableHandle(props => (
  <b style={{ visibility: props.visibility ? 'visible' : 'hidden' }} className="dragComp">
    <i className="icon icon-enlarge" />
  </b>
));
const SortableItem = SortableElement(props => props.children);
const SortableList = SortableContainer(props => props.children);
const REQUISITE_NOT_FINISHED_VALUE = 27;
const REQUISITE_NOT_APPLICABLE_VALUE = 26;
const REQUISITE_FINISHED_VALUE = 25;
const REQUISITE_TYPE_T = 'T';
export function textSearchFormat(text) {
  if (typeof text !== 'string') {
    return '';
  }
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

class RequisiteIndex extends Component {
  constructor(oProps) {
    super(oProps);
    const { match } = this.props;
    let bActivated = true;

    if (match.params.bDeadline && match.params.bDeadline === '1') {
      bActivated = false;
    }

    this.state = {
      bLoading: true,
      filterSearch: '',
      bShowFinished: bActivated,
      bShowUnfinished: bActivated,
      bShowLates: true,
      bActualMenuFolder: false,
      nNormId: match.params.nNormId,
      aPages: [{ cName: 'QualityPanelIndex.titulo', cLink: '/quality-panel' }],
      aRequisites: [],
      aFiltredRequisites: [],
      oNorm: [],
      canUserCreateEdit: false,
      canUserAccess: false
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  onSortEnd = ({ oldIndex, newIndex, nodes }) => {
    this.setState(
      ({ aRequisites }) => ({
        aRequisites: arrayMoveMutable(aRequisites, oldIndex, newIndex)
      }),
      () => {
        const { aRequisites } = this.state;
        let aNewIndexes = aRequisites.map((oReq, index) => {
          return { req_nid: Number(oReq.req_nid), nNewIndex: index };
        });
        Axios.post(`/requisite/change-order`, aNewIndexes);
        this.applyFilter();
      }
    );
  };

  sortChildrenById = (aRawData, nOldIndex, nNewIndex, oReq) => {
    for (let i = 0; i < aRawData.length; i++) {
      if (aRawData[i].req_nid === oReq.req_nid) {
        aRawData[i].recursive_children_requisite = arrayMoveMutable(
          oReq.recursive_children_requisite,
          nOldIndex,
          nNewIndex
        );
      } else if (aRawData[i].recursive_children_requisite.length) {
        this.sortChildrenById(aRawData[i].recursive_children_requisite, nOldIndex, nNewIndex, oReq);
      }
    }
    return aRawData;
  };

  findById = (aRawData, oReq) => {
    for (let i = 0; i < aRawData.length; i++) {
      if (aRawData[i].req_nid === oReq.req_nid) {
        return aRawData[i];
      } else if (aRawData[i].recursive_children_requisite.length) {
        const oRecursiveFound = this.findById(aRawData[i].recursive_children_requisite, oReq);
        if (oRecursiveFound) return oRecursiveFound;
      }
    }
    return null;
  };

  onSortEndChilds = (oReq, { oldIndex, newIndex, nodes }) => {
    const { aRequisites } = this.state;
    const aNewRequisites = this.sortChildrenById(aRequisites, oldIndex, newIndex, oReq);
    const oNewRequisite = this.findById(aNewRequisites, oReq);

    this.setState({ aRequisites: aNewRequisites }, () => {
      let aNewIndexes = oNewRequisite.recursive_children_requisite.map((oItem, nIndex) => {
        return { req_nid: Number(oItem.req_nid), nNewIndex: nIndex };
      });

      Axios.post(`/requisite/change-order`, aNewIndexes);
      this.applyFilter();
    });
  };

  componentDidUpdate = () => {
    this.applyMouseenter();
  };

  componentDidMount = () => {
    const { nNormId } = this.state;
    const { setAlert, history } = this.props;
    checkModule(history, 'quality-panel');

    document.addEventListener('mousedown', this.handleClickOutside);
    Promise.all([
      getRequisites(nNormId).then(oData => {
        this.setState({
          ...oData
        });
      }),
      getNorm(nNormId).then(oData => {
        this.setState({
          ...oData
        });
      })
    ])
      .then(() => {
        this.setState({
          bLoading: false,
          canUserCreateEdit: this.checkIfUserCanCreateEdit(),
          canUserAccess: this.checkIfUserCanAccess()
        });
        this.applyFilter();
        this.applyMouseenter();
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.msgErrors);
      });
  };
  applyMouseenter = () => {
    $('.body-table li')
      .on('mouseover', function (evt) {
        $(this)
          .addClass('enter')
          .parents()
          .removeClass('enter');
        evt.stopPropagation();
      })
      .on('mouseout', function () {
        $(this).removeClass('enter');
      });
  };

  setWrapperRef(cNode) {
    this.wrapperRef = cNode;
  }

  deleteNorm = nNormId => {
    const { clearAlert, history } = this.props;
    const { canUserCreateEdit } = this.state;

    if (canUserCreateEdit) {
      Axios.delete(`norm/${nNormId}`)
        .then(() => {
          this.setState(
            {
              rcmpAlert: (
                <SweetAlert
                  success
                  title={intl.get('removida')}
                  onConfirm={() => {
                    history.push(`/quality-panel`);
                  }}
                >
                  {intl.get('RequisiteIndex.norma_excluida')}
                </SweetAlert>
              )
            },
            () => {
              clearAlert();
            }
          );
        })
        .catch(oError => {
          this.setState({
            rcmpAlert: (
              <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
                {intl.get('falhou')}
                <p>{oError.msgErrors}</p>
              </SweetAlert>
            )
          });
        });
    }
  };

  hideAlert = () => {
    this.componentDidMount();
    this.setState({
      rcmpAlert: null
    });
  };

  showDeleteNorm = (evt, nNormId, cNormInitials) => {
    evt.preventDefault();
    const { canUserCreateEdit } = this.state;

    if (canUserCreateEdit) {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            showCancel
            confirmBtnText={intl.get('confirmar')}
            cancelBtnText={intl.get('cancelar')}
            cancelBtnBsStyle="danger"
            title={intl.get('RequisiteIndex.titulo_excluir_norma', { cNormInitials: cNormInitials })}
            onConfirm={() => this.deleteNorm(nNormId)}
            onCancel={this.hideAlert}
          >
            {intl.get('RequisiteIndex.excluir_norma')}
          </SweetAlert>
        )
      });
    }
  };

  showDeleteRequisite = (evt, oRequisite) => {
    evt.preventDefault();
    const { canUserCreateEdit } = this.state;

    if (canUserCreateEdit) {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            showCancel
            confirmBtnText={intl.get('confirmar')}
            cancelBtnText={intl.get('cancelar')}
            cancelBtnBsStyle="danger"
            title={`${intl.get('RequisiteIndex.titulo_excluir_requisito')} ${oRequisite.req_cname}`}
            onConfirm={() => this.deleteRequisite(oRequisite)}
            onCancel={this.hideAlert}
          >
            {intl.get('RequisiteIndex.excluir_requisito')}
            <p>{intl.get('RequisiteIndex.evidencias_serao_perdidas')}</p>
          </SweetAlert>
        )
      });
    }
  };

  deleteRequisite = oRequisite => {
    const { clearAlert } = this.props;
    const { canUserCreateEdit } = this.state;

    if (canUserCreateEdit) {
      Axios.delete(`requisite/${oRequisite.req_nid}`)
        .then(() => {
          this.setState(
            {
              rcmpAlert: (
                <SweetAlert
                  success
                  title={`${oRequisite.req_cname} ${intl.get('removido')}`}
                  onConfirm={() => {
                    this.componentDidMount();
                    this.hideAlert();
                  }}
                >
                  {intl.get('RequisiteIndex.requisito_excluido')}
                </SweetAlert>
              )
            },
            () => {
              clearAlert();
            }
          );
        })
        .catch(oError => {
          this.setState({
            rcmpAlert: (
              <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
                {!oError?.response?.data ? intl.get('falhou') : <p>{oError.response.data}</p>}
              </SweetAlert>
            )
          });
        });
    }
  };

  openEditRequisite = (evt, oRequisite) => {
    evt.preventDefault();
    const { oNorm, canUserCreateEdit } = this.state;

    if (canUserCreateEdit) {
      this.setState({
        rcmpAlert: (
          <SweetAlert customClass="modal-edit md ScrollBar" title="" onConfirm={this.hideAlert} showConfirm={false}>
            <div className="head-modal">
              {oNorm.norm_cinitials} - {oRequisite.req_cname}
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyPress={this.hideAlert}
                onClick={this.hideAlert}
                className="close"
                href=""
              />
            </div>
            <ScrollBar>
              <Requisite
                nId={oRequisite.req_nid}
                nNormId={oRequisite.norm_nid}
                closeModal={this.hideAlert}
                onSuccess={cMsg => {
                  this.successMessage(cMsg);
                }}
              />
            </ScrollBar>
          </SweetAlert>
        )
      });
    }
  };

  menuOpen = () => {
    this.setState(oCurrentState => ({
      bActualMenuFolder: !oCurrentState.bActualMenuFolder
    }));
  };

  loadChild = evt => {
    this.elNodeLi = evt.target;

    if (evt.target.classList.contains('insidespan') === false) {
      if (evt.target.closest('li').classList.contains('open')) {
        evt.target.closest('li').classList.toggle('open');
        if (evt.target.closest('li').getElementsByClassName('subLevel')[0]) {
          evt.target
            .closest('li')
            .getElementsByClassName('subLevel')[0]
            .classList.toggle('open');
        }
      } else if (evt.target.closest('li').getElementsByClassName('subLevel')[0]) {
        this.elNodeLi.closest('li').classList.toggle('open');
        const elHoShow = evt.target.closest('li').getElementsByClassName('subLevel')[0];
        elHoShow.classList.toggle('open');
      }
    }
  };

  closeContextMenu = (e, wrapperRef) => {
    const element = wrapperRef;
    if (element.getElementsByClassName('changeState')[0]) {
      element.getElementsByClassName('changeState')[0].style = 'display: none';
    }
  };

  openContextMenu = e => {
    const { canUserCreateEdit } = this.state;
    if (canUserCreateEdit && e.target.closest('div').getElementsByClassName('changeState')[0]) {
      e.target.closest('div').getElementsByClassName('changeState')[0].style = 'display: block';
    }
  };

  renderChilds = (oRequisite, subLevel) => {
    const { oNorm } = this.state;
    const { history } = this.props;
    return (
      <>
        {oRequisite.recursive_children_requisite && Object.keys(oRequisite.recursive_children_requisite).length > 0 && (
          <div className={`subLevel ${oRequisite.open ? 'open' : ''}`}>
            <SortableList
              useDragHandle
              helperClass="tab"
              transitionDuration={0}
              helperContainer={this.containerEl}
              distance={10}
              onSortEnd={this.onSortEndChilds.bind(this, oRequisite)}
            >
              <ul ref={this[`${oRequisite.req_nid}_ref`]}>
                {Object.keys(oRequisite.recursive_children_requisite).map((cKey, i) => (
                  <SortableItem
                    value={oRequisite.recursive_children_requisite[cKey].req_nid}
                    sortKey={oRequisite.recursive_children_requisite[cKey].req_nid}
                    key={`drag2ItemF-${i}-${oRequisite.recursive_children_requisite[cKey].req_nid}`}
                    index={i}
                  >
                    <li
                      itemID={oRequisite.recursive_children_requisite[cKey].req_nid}
                      key={`req${oRequisite.recursive_children_requisite[cKey].req_nid}`}
                      className={`${oRequisite.recursive_children_requisite[cKey].open ? 'open' : ''
                        } ${this.getFeaturedClassName(oRequisite.recursive_children_requisite[cKey])}`}
                      role="button"
                      tabIndex={i}
                      onKeyPress={evt => {
                        evt.stopPropagation();
                        this.loadChild(evt, oRequisite.recursive_children_requisite[cKey]);
                      }}
                      onClick={evt => {
                        evt.stopPropagation();
                        this.loadChild(evt, oRequisite.recursive_children_requisite[cKey]);
                      }}
                    >
                      <DragHandle visibility={true} />

                      {this.renderRequisiteTitleDescription(oRequisite.recursive_children_requisite[cKey], subLevel)}

                      <div className="optionsContainerTable">
                        <span
                          onClick={e => {
                            e.stopPropagation();
                            this.openContextMenu(e);
                          }}
                          className={`status insidespan status-${oRequisite.recursive_children_requisite[cKey].stat_nid
                            } ${oRequisite.recursive_children_requisite[cKey].req_etitletype === 'T' ? 'no-visible' : ''
                            }`}
                        >
                          {this.getStatusName(oRequisite.recursive_children_requisite[cKey].stat_nid) ?? ''}

                          {this.renderContextMenu(oRequisite.recursive_children_requisite[cKey])}
                        </span>
                        <span className="responsible">
                          {oRequisite.recursive_children_requisite[cKey].user
                            ? oRequisite.recursive_children_requisite[cKey].user.user_cname
                            : ''}
                        </span>

                        {oNorm && oNorm.norm_bdeadlineactive ? (
                          <span
                            className={`deadline${this.validateLates(oRequisite.recursive_children_requisite[cKey].req_ddeadline) &&
                              oRequisite.recursive_children_requisite[cKey].stat_nid === REQUISITE_NOT_FINISHED_VALUE
                              ? ' late'
                              : ''
                              }`}
                          >
                            <span>
                              {oRequisite.recursive_children_requisite[cKey].req_ddeadline &&
                                oRequisite.recursive_children_requisite[cKey].req_etitletype !== 'T'
                                ? moment
                                  .parseZone(oRequisite.recursive_children_requisite[cKey].req_ddeadline)
                                  .format('DD/MM/YYYY')
                                : ''}
                            </span>
                          </span>
                        ) : (
                          ''
                        )}

                        <span
                          className={`${oRequisite.recursive_children_requisite[cKey].notConformRequisite === 1
                            ? 'updatedAt mobile480-hide noCompliaceIcon'
                            : 'updatedAt mobile480-hide'
                            }`}
                        >
                          {oRequisite.recursive_children_requisite[cKey].req_dedited
                            ? intl.get('RequisiteIndex.atualizado_em', {
                              cDate: moment
                                .parseZone(oRequisite.recursive_children_requisite[cKey].req_dedited)
                                .tz('America/Sao_Paulo')
                                .format('DD/MM/YYYY')
                            })
                            : ''}
                        </span>
                        <span
                          className={`${oRequisite.recursive_children_requisite[cKey].notConformRequisite === 1
                            ? 'optionsHover mobile480-hide noCompliaceIconVisible'
                            : 'optionsHover mobile480-hide noCompliaceIconHide'
                            }`}
                        >
                          {this.renderRequisiteMenu(oRequisite.recursive_children_requisite[cKey], oNorm)}
                        </span>
                        {oRequisite.recursive_children_requisite[cKey].notConformRequisite === 1 && (
                          <i
                            tooltiptext={intl.get('RequisiteIndex.nao_conforme')}
                            className="non-compliance"
                            onClick={e => {
                              const audi_nid = oRequisite.recursive_children_requisite[cKey]?.audi_nid ?? null;
                              if (
                                audi_nid &&
                                (can('admin', 'audit-management') || can('access-auditmanagement', 'audit-management'))
                              ) {
                                e.stopPropagation();
                                e.preventDefault();
                                history.push({
                                  pathname: `/requisite-audit/${audi_nid}`,
                                  state: { nInitialRequisiteID: oRequisite.recursive_children_requisite[cKey].req_nid }
                                });
                              }
                            }}
                          ></i>
                        )}
                      </div>
                      {this.renderChilds(oRequisite.recursive_children_requisite[cKey], subLevel + 1)}
                    </li>
                  </SortableItem>
                ))}
              </ul>
            </SortableList>
          </div>
        )}
      </>
    );
  };

  openRequisite = (evt, oRequisite) => {
    evt.preventDefault();
    const { oNorm } = this.state;
    const { setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow ScrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
          onCancel={this.hideAlert}
        >
          <div className="head-modal">
            {oRequisite.req_cname}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <RequisiteShow
            nRequisiteId={oRequisite.req_nid}
            nNormId={oNorm.norm_nid}
            setAlert={setAlert}
            onSuccess={cMsg => {
              this.successMessage(cMsg);
            }}
            closeModal={this.hideAlert}
          />
        </SweetAlert>
      )
    });
  };

  successMessage = cMsg => {
    this.hideAlert();
    this.componentDidMount();

    this.setState({
      rcmpAlertMsg: (
        <Alert type="success" isOpen onCloseAlert={() => { }}>
          {cMsg}
        </Alert>
      )
    });
  };

  openRequisites = evt => {
    evt.preventDefault();
    const { oNorm } = this.state;

    this.setState({
      rcmpAlert: (
        <SweetAlert customClass="modal-edit md ScrollBar" title="" onConfirm={this.hideAlert} showConfirm={false}>
          <div className="head-modal">
            {intl.get('RequisiteIndex.novo_requisito')} - {oNorm.norm_cinitials}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <Requisite
              nNormId={oNorm.norm_nid}
              oNorm={oNorm}
              onSuccess={cMsg => {
                this.successMessage(cMsg);
              }}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  changeStatus = async (evt, nRequisiteId, nStatusId) => {
    evt.preventDefault();
    const { canUserCreateEdit } = this.state;

    if (canUserCreateEdit) {
      evt.persist();
      evt.target.closest('span').classList.add('load');
      evt.target.closest('div').style.display = 'none';

      Axios.post('/requisite/change-status', {
        req_nid: nRequisiteId,
        stat_nid: nStatusId
      })
        .then(() => {
          this.componentDidMount();
        })
        .catch(oError => {
          this.setState({
            rcmpAlert: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.msgErrors}
              </Alert>
            )
          });
        });
    }
  };

  openNormEdit = (evt, oNorm) => {
    evt.preventDefault();
    const { canUserCreateEdit } = this.state;

    if (canUserCreateEdit) {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            customClass="modal-edit md disabled-overflow ScrollBar"
            title=""
            onConfirm={this.hideAlert}
            showConfirm={false}
          >
            <div className="head-modal">
              {intl.get('editar', { tipo: oNorm.norm_cname })}
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyPress={this.hideAlert}
                onClick={this.hideAlert}
                className="close"
                href=""
              />
            </div>
            <ScrollBar>
              <Norm
                oNorm={oNorm}
                onSuccess={cMsg => {
                  this.successMessage(cMsg);
                }}
                closeModal={this.hideAlert}
              />
            </ScrollBar>
          </SweetAlert>
        )
      });
    }
  };

  requisiteDescription = aSections => {
    const oSectionDescription = aSections.find(oSection => oSection.rs_cdescription);
    return oSectionDescription ? oSectionDescription.rs_cdescription : '';
  };

  handleClickOutside(evt) {
    if (this.wrapperRef && !this.wrapperRef.contains(evt.target)) {
      this.setState({
        bActualMenuFolder: false
      });
    }
  }

  renderRequisiteTitleDescription = (oRequisite, subLevel) => (
    <span>
      <div className="requisite-item-list-title">
        <div>
          {oRequisite.children_requisites_count > 0 && (
            <span>
              <i className="icon icon-next" />
            </span>
          )}
          &nbsp;
        </div>
        <div
          className={this.requisiteDescription(oRequisite.requisite_section) === '' ? 'inc-width' : ''}
          style={{ paddingLeft: subLevel * 15 }}
        >
          {oRequisite.req_cname}
        </div>
        {this.requisiteDescription(oRequisite.requisite_section) !== '' && (
          <div className="requisite-item-list-description">
            {this.requisiteDescription(oRequisite.requisite_section)}
          </div>
        )}
      </div>
    </span>
  );

  renderRequisiteMenuMobile = oRequisite => {
    return (
      can('create-edit-norm-qualitypanel', 'quality-panel') && (
        <OptionMenu>
          <li>
            <a href="/#openRequisite" className="insidespan" onClick={evt => this.openRequisite(evt, oRequisite)}>
              {intl.get('visualizar')}
            </a>
          </li>
          {(oRequisite.stat_nid === 27 || oRequisite.stat_nid === 28) && (
            <>
              <li>
                <a
                  href="/#changeStatus"
                  className="insidespan"
                  onClick={evt => this.changeStatus(evt, oRequisite.req_nid, 25)}
                >
                  {intl.get('RequisiteIndex.status_concluido')}
                </a>
              </li>
              <li>
                <a
                  href="/#changeStatus"
                  className="insidespan"
                  onClick={evt => this.changeStatus(evt, oRequisite.req_nid, 26)}
                >
                  {intl.get('RequisiteIndex.status_nao_aplica')}
                </a>
              </li>
            </>
          )}
          {(oRequisite.stat_nid === 25 || oRequisite.stat_nid === 26) && (
            <li>
              <a
                href="/#changeStatus"
                className="insidespan"
                onClick={evt => this.changeStatus(evt, oRequisite.req_nid, 28)}
              >
                {intl.get('RequisiteIndex.status_andamento')}
              </a>
            </li>
          )}
          {this.checkRequisiteEditPermission(oRequisite) && (
            <li>
              <a
                href="/#showDeleteRequisite"
                className="insidespan"
                onClick={evt => this.showDeleteRequisite(evt, oRequisite)}
              >
                {intl.get('excluir', { tipo: '' })}
              </a>
            </li>
          )}
          {this.checkRequisiteEditPermission(oRequisite) && (
            <li>
              <a
                href="/#openEditRequisite"
                className="insidespan"
                onClick={evt => this.openEditRequisite(evt, oRequisite)}
              >
                {intl.get('editar', { tipo: '' })}
              </a>
            </li>
          )}
        </OptionMenu>
      )
    );
  };

  renderRequisiteMenu = (oRequisite, oNorm) => {
    const { canUserCreateEdit } = this.state;
    return (
      can('create-edit-norm-qualitypanel', 'quality-panel') &&
      canUserCreateEdit && (
        <>
          <a
            tooltiptext={intl.get('visualizar')}
            href="/#openRequisite"
            className="insidespan"
            onClick={evt => {
              evt.stopPropagation();
              this.openRequisite(evt, oRequisite);
            }}
          >
            <i className="icon icon-eye" />
          </a>
          {!(oRequisite.recursive_children_requisite && Boolean(oRequisite.recursive_children_requisite.length)) &&
            Boolean(oNorm.norm_bisactive) &&
            this.checkRequisiteEditPermission(oRequisite) && (
              <a
                tooltiptext={intl.get('excluir', { tipo: '' })}
                href="/#showDeleteRequisite"
                className="insidespan"
                onClick={evt => {
                  evt.stopPropagation();
                  this.showDeleteRequisite(evt, oRequisite);
                }}
              >
                <i className="icon icon-bin" />
              </a>
            )}
          {Boolean(oNorm.norm_bisactive) && this.checkRequisiteEditPermission(oRequisite) && (
            <a
              tooltiptext={intl.get('editar', { tipo: '' })}
              href="/#openEditRequisite"
              className="insidespan"
              onClick={evt => {
                evt.stopPropagation();
                this.openEditRequisite(evt, oRequisite);
              }}
            >
              <i className="icon icon-edit" />
            </a>
          )}
        </>
      )
    );
  };

  handleChangeCheckbox = e => {
    this.setState(
      {
        [e.target.name]: e.target.checked
      },
      () => {
        this.applyFilter();
      }
    );
  };

  handleChange = e => {
    const { aRequisites } = this.state;
    this.setState(
      {
        filtredRequisites: aRequisites,
        [e.target.name]: e.target.value
      },
      () => {
        this.applyFilter();
      }
    );
  };

  handleChangeFilter = (e, oFilter) => {
    this.setState(
      {
        filterSearch: oFilter.cText
      },
      () => {
        this.applyFilter();
      }
    );
  };

  renderContextMenu = oRequisite => {
    const { canUserCreateEdit } = this.state;
    if (!canUserCreateEdit) {
      return null;
    }

    return (
      <OutsideHandlerClick handleClickOutside={this.closeContextMenu}>
        <div className="changeState" id="changeState">
          {oRequisite.stat_nid !== 25 && (
            <a onClick={evt => this.changeStatus(evt, oRequisite.req_nid, 25)} className="finished" href="#">
              {' '}
              {intl.get('RequisiteIndex.status_concluido')}
            </a>
          )}
          {oRequisite.stat_nid !== 27 && (
            <a onClick={evt => this.changeStatus(evt, oRequisite.req_nid, 27)} className="pending" href="#">
              {intl.get('RequisiteIndex.status_pendente')}
            </a>
          )}
          {oRequisite.stat_nid !== 26 && (
            <a onClick={evt => this.changeStatus(evt, oRequisite.req_nid, 26)} className="n-applied" href="#">
              {intl.get('RequisiteIndex.status_nao_aplica')}
            </a>
          )}
        </div>
      </OutsideHandlerClick>
    );
  };

  getStatusName = statusId => {
    let status = {
      25: intl.get('RequisiteIndex.status_concluido'),
      26: intl.get('RequisiteIndex.status_nao_aplica'),
      27: intl.get('RequisiteIndex.status_pendente'),
      28: intl.get('RequisiteIndex.status_andamento')
    };
    return status[statusId];
  };

  filterByName = (requisite, filterSearch) => {
    return textSearchFormat(requisite.req_cname).includes(textSearchFormat(String(filterSearch)));
  };

  filterByStatus = (requisite, filterSearch) => {
    const status = this.getStatusName(requisite.status_requisite.stat_nid);

    return (
      requisite.req_etitletype !== REQUISITE_TYPE_T &&
      status &&
      textSearchFormat(status).includes(textSearchFormat(String(filterSearch)))
    );
  };

  filterByResponsible = (requisite, filterSearch) => {
    return textSearchFormat(requisite.user.user_cname).includes(textSearchFormat(String(filterSearch)));
  };

  filterByDate = (requisite, filterSearch) => {
    return moment
      .parseZone(requisite.req_dedited)
      .tz('America/Sao_Paulo')
      .format('DD/MM/YYYY')
      .toLowerCase()
      .includes(String(filterSearch).toLowerCase());
  };

  validateLates = cDate => {
    const bIsLate = moment(cDate).isSame(moment(), 'day');
    if (bIsLate) {
      return false;
    }

    return moment(cDate).isBefore(moment(), 'day');
  };

  filterByCheckboxes = oRequisite => {
    const { bShowFinished, bShowUnfinished, bShowLates } = this.state;

    if (oRequisite.req_etitletype === REQUISITE_TYPE_T && oRequisite.children_requisites_count === 0) {
      return bShowFinished || bShowUnfinished;
    }

    const bIsLate = this.validateLates(oRequisite.req_ddeadline);

    return (
      (oRequisite.req_etitletype !== REQUISITE_TYPE_T &&
        ((bShowFinished && [REQUISITE_FINISHED_VALUE, REQUISITE_NOT_APPLICABLE_VALUE].includes(oRequisite.stat_nid)) ||
          (bShowUnfinished && oRequisite.stat_nid === REQUISITE_NOT_FINISHED_VALUE))) ||
      (bShowLates && bIsLate && [REQUISITE_NOT_FINISHED_VALUE].includes(oRequisite.stat_nid))
    );
  };

  checkAllFilters = (oRequisite, filterSearch) => {
    oRequisite.open = false;
    if (
      (this.filterByName(oRequisite, filterSearch) ||
        this.filterByStatus(oRequisite, filterSearch) ||
        this.filterByResponsible(oRequisite, filterSearch) ||
        this.filterByDate(oRequisite, filterSearch)) &&
      this.filterByCheckboxes(oRequisite)
    ) {
      oRequisite.open = true;
      return Boolean(
        (oRequisite.recursive_children_requisite = oRequisite.recursive_children_requisite.filter(recursive_rec =>
          this.checkAllFilters(recursive_rec, filterSearch)
        ))
      );
    }

    if (oRequisite.recursive_children_requisite) {
      oRequisite.open = true;
      return Boolean(
        (oRequisite.recursive_children_requisite = oRequisite.recursive_children_requisite.filter(recursive_rec =>
          this.checkAllFilters(recursive_rec, filterSearch)
        )).length
      );
    }

    return false;
  };

  applyFilter = () => {
    const { filterSearch, aRequisites } = this.state;

    let aFiltredNow = JSON.parse(JSON.stringify(aRequisites)).filter(req => this.checkAllFilters(req, filterSearch));

    this.setState({
      aFiltredRequisites: aFiltredNow.length ? aFiltredNow : []
    });

    setTimeout(() => {
      this.applyMouseenter();
    }, 1);
  };

  getFeaturedClassName = requisite => {
    let featured = false;

    if (requisite.req_etitletype === REQUISITE_TYPE_T) {
      if (!requisite.req_nid_parent && requisite.children_requisites_count) {
        featured = this.canHighlightFirstLevelRequisite(requisite, REQUISITE_NOT_FINISHED_VALUE);
      } else {
        featured = requisite.children_requisites_count
          ? this.canHighlightFirstLevelRequisite(requisite, REQUISITE_NOT_FINISHED_VALUE)
          : requisite.status_requisite.stat_nid !== REQUISITE_NOT_FINISHED_VALUE;
      }

      return featured ? 'all-finished' : 'non-finished';
    }

    return [REQUISITE_FINISHED_VALUE, REQUISITE_NOT_APPLICABLE_VALUE].includes(requisite.status_requisite.stat_nid)
      ? 'all-finished'
      : 'non-finished';
  };
  canHighlightFirstLevelRequisite = (requisiteToValidate, incompleteValueCondition) => {
    let statuses = [];
    const statusesFromChildren = getRequisiteStatusInDepth(requisiteToValidate);

    return !JSON.stringify(statusesFromChildren).includes(incompleteValueCondition);

    function getRequisiteStatusInDepth(requisiteToSearch) {
      if (requisiteToSearch.children_requisites_count) {
        requisiteToSearch.recursive_children_requisite.forEach(req => {
          if (req.req_etitletype !== REQUISITE_TYPE_T) statuses = [...statuses, req.status_requisite.stat_nid];
          return getRequisiteStatusInDepth(req);
        });
      }
      return statuses;
    }
  };

  changeStateNorm = (e, { norm_nid, bisactive }) => {
    e.preventDefault();
    const { canUserCreateEdit } = this.state;

    if (canUserCreateEdit) {
      Axios.get(`/norm/change-status/${norm_nid}`)
        .then(response => {
          const { oNorm } = this.state;
          oNorm.norm_bisactive = oNorm.norm_bisactive === 1 ? 0 : 1;

          this.setState({
            oNorm
          });
        })
        .catch(error => {
          this.setState({
            oLoadNorm: '',
            alert: (
              <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
                {intl.get('falhou')}
                <p>{error.msgErrors}</p>
              </SweetAlert>
            )
          });
        });
    }
  };

  checkNormEditPermission = () => {
    const { oNorm } = this.state;
    const nUserId = Number(oLocalStorage.get('nUserId'));
    return (
      can('admin', 'quality-panel') ||
      (can('create-edit-norm-qualitypanel', 'quality-panel') &&
        oNorm.norm_responsibles?.some(responsible => responsible?.user?.user_nid === nUserId))
    );
  };

  checkRequisiteEditPermission = oRequisite => {
    return (
      can('admin', 'quality-panel') ||
      (can('create-edit-norm-qualitypanel', 'quality-panel') &&
        (this.checkIfUserIsResponsible() || oRequisite?.user?.user_nid === Number(oLocalStorage.get('nUserId'))))
    );
  };

  recursivelyCheckRequisiteResponsible = (nUserId, oReq, bHasPerm) => {
    if (!oReq) return false;
    if (bHasPerm || oReq.user?.user_nid === nUserId) {
      return true;
    }
    if (oReq.recursive_children_requisite?.length > 0) {
      return oReq.recursive_children_requisite.some(
        oChildReq => this.recursivelyCheckRequisiteResponsible(nUserId, oChildReq, bHasPerm)
      );
    }
    return false;
  };

  checkIfUserIsResponsibleForRequisite = () => {
    const { aRequisites } = this.state;
    const nUserId = Number(oLocalStorage.get('nUserId'));
    return !!aRequisites
      && aRequisites.some(oReq => this.recursivelyCheckRequisiteResponsible(nUserId, oReq, false));
  };

  checkIfUserIsResponsible = () => {
    const { oNorm } = this.state;
    const nUserId = Number(oLocalStorage.get('nUserId'));
    return (
      oNorm.norm_consultants?.some(
        consultant => consultant?.consultant?.user?.user_nid === nUserId
      ) ||
      oNorm.norm_responsibles?.some(
        responsible => responsible?.user?.user_nid === nUserId
      )
    );
  };

  checkIfUserCanAccess = () => {
    return (
      can('admin', 'quality-panel') ||
      (can('access-qualitypanel', 'quality-panel') &&
        (this.checkIfUserIsResponsible() || this.checkIfUserIsResponsibleForRequisite()))
    );
  };

  checkIfUserCanCreateEdit = () => {
    return (
      can('admin', 'quality-panel') ||
      (can('create-edit-norm-qualitypanel', 'quality-panel') &&
        (this.checkIfUserIsResponsible() || this.checkIfUserIsResponsibleForRequisite()))
    );
  };

  renderBreadcrumbs = () => {
    const { oNorm, aPages } = this.state;

    return (
      <div className="breadcrumbs-wrapper" style={{ fontSize: '14px' }}>
        <div className="inner-bread">
          <Breadcrumb bLastLink aPages={aPages} />
        </div>
        <span>{'>'}</span>
        <span>{oNorm.norm_cinitials}</span>
      </div>
    );
  };

  renderRightMenu = () => {
    const { bActualMenuFolder, oNorm, nNormId, canUserCreateEdit } = this.state;
    const { history } = this.props;
    const bNormEditPermission = this.checkNormEditPermission();
    return (
      <div className="right-menu">
        <span
          role="button"
          tabIndex="0"
          aria-labelledby="right-menu"
          onKeyPress={() => this.menuOpen()}
          onClick={() => this.menuOpen()}
          className="btn-menu"
        >
          <span />
        </span>

        <ul style={{ display: bActualMenuFolder ? 'block' : 'none' }} className="submenu">
          {bNormEditPermission && Boolean(oNorm.norm_bisactive) && (
            <li>
              <a
                onClick={evt => {
                  this.openNormEdit(evt, oNorm);
                }}
                href="#edit-norm"
              >
                {intl.get('QualityPanelIndex.editar_norma')}
              </a>
            </li>
          )}

          {bNormEditPermission && (
            <li>
              <a onClick={evt => this.changeStateNorm(evt, oNorm)} href="#inactive-norm">
                {Boolean(oNorm.norm_bisactive) ? intl.get('inativar') : intl.get('ativar')}
              </a>
            </li>
          )}

          <li>
            <a
              onClick={evt => {
                evt.stopPropagation();
                evt.preventDefault();
                history.push(`/norm/interaction/${oNorm.norm_nid}`);
              }}
              href="#state-change"
            >
              {intl.get('RequisiteIndex.interacoes')}
            </a>
          </li>

          {bNormEditPermission && Boolean(oNorm.norm_bisactive) && (
            <li>
              <a
                onClick={evt => this.showDeleteNorm(evt, nNormId, oNorm.norm_cinitials)}
                style={{ color: '#c66' }}
                href="#delete-norm"
              >
                {intl.get('QualityPanelIndex.deletar_norma')}
              </a>
            </li>
          )}
        </ul>
      </div>
    );
  };

  renderProgressBar = () => {
    const { oNorm } = this.state;
    const progress = oNorm.finishedRequisites !== 0 ? oNorm.norm_nprogress : 0;
    return (
      <div className={`containerBar ${oNorm && oNorm.norm_bdeadlineactive ? 'deadline' : ''}`}>
        <span className="tooltipNorma">
          <p>
            {intl.get('RequisiteIndex.status_concluido')} : {oNorm.finishedRequisites}{' '}
          </p>
          <p>
            {intl.get('RequisiteIndex.status_nao_aplica')} : {oNorm.notAppliedRequisites}{' '}
          </p>
          <p>
            {intl.get('RequisiteIndex.status_pendente')} : {oNorm.pendingRequisites}{' '}
          </p>
        </span>
        <div className="progressBar">
          <span
            style={{
              width: `${progress}%`
            }}
            className="fill"
          />
        </div>

        <span className="percent">{`${progress}%`}</span>
      </div>
    );
  };

  generateCardHeader = () => {
    const { bShowFinished, bShowUnfinished, bShowLates, oNorm, canUserCreateEdit } = this.state;
    const { history } = this.props;
    const aButtons = [];

    const rcmpBtnNewStandard = (
      <Button
        onClick={event => {
          this.openRequisites(event);
        }}
      >
        {intl.get('RequisiteIndex.novo_requisito')}
      </Button>
    );

    aButtons.push(
      can('create-edit-norm-qualitypanel', 'quality-panel') && canUserCreateEdit && Boolean(oNorm.norm_bisactive)
        ? rcmpBtnNewStandard
        : []
    );

    const aCheckboxes = [];
    const rcmpFinishedSwitch = (
      <AltCheckbox
        key="checkbox-show-finished"
        onChange={this.handleChangeCheckbox}
        title={intl.get('RequisiteIndex.completos')}
        label={intl.get('RequisiteIndex.completos')}
        name="bShowFinished"
        localeLabel="end"
        checked={bShowFinished}
      />
    );
    const rcmpUnfinishedSwitch = (
      <AltCheckbox
        key="checkbox-show-unfinished"
        onChange={this.handleChangeCheckbox}
        title={intl.get('RequisiteIndex.incompletos')}
        label={intl.get('RequisiteIndex.incompletos')}
        name="bShowUnfinished"
        localeLabel="end"
        checked={bShowUnfinished}
      />
    );

    let rcmpLateSwitch = '';

    if (oNorm && oNorm.norm_bdeadlineactive) {
      rcmpLateSwitch = (
        <AltCheckbox
          key="checkbox-show-lated"
          onChange={this.handleChangeCheckbox}
          title={intl.get('RequisiteIndex.lates')}
          label={intl.get('RequisiteIndex.lates')}
          name="bShowLates"
          localeLabel="end"
          checked={bShowLates}
        />
      );
    }

    aCheckboxes.push(rcmpFinishedSwitch);
    aCheckboxes.push(rcmpUnfinishedSwitch);
    aCheckboxes.push(rcmpLateSwitch);

    const rcmpFilter = (
      <Filter
        aButtons={aButtons}
        fnOnType={this.handleChangeFilter}
        bIsOrderActive={false}
        aCheckboxes={aCheckboxes}
        rcmpRightMenu={this.renderRightMenu()}
        rcmpProgressBar={this.renderProgressBar()}
        history={history}
      />
    );

    const rcmpCardHeader = (
      <div ref={this.setWrapperRef}>
        <CardHeader rcmpFilter={rcmpFilter} bIsItToInsertGoBackBtn={false} />
      </div>
    );
    return rcmpCardHeader;
  };

  render() {
    const { aRequisites, aFiltredRequisites, bLoading, rcmpAlert, oNorm, rcmpAlertMsg, canUserAccess } = this.state;

    const { history, getAlert } = this.props;

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={this.renderBreadcrumbs()}
        cTitle={oNorm.norm_cinitials}
        cImage={qualityImg}
        cDescription={oNorm.norm_cname}
        rcmpCardHeader={canUserAccess ? this.generateCardHeader() : null}
        cCurrentSideMenuOption={intl.get('Nav.quality_panel')}
        className="table-page"
      >
        <div className="alert-container">
          {rcmpAlert}
          {rcmpAlertMsg}
          {getAlert()}
          {!canUserAccess && (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('sem_permissao')}
            </Alert>
          )}
        </div>

        {canUserAccess && (
          <div className={`lastOption ${oNorm && oNorm.norm_bdeadlineactive ? 'deadline' : ''}`} id="table-emule">
            <div className="head-table">
              <ul>
                <li>{intl.get('Norm.requisitos')}</li>
                <li>{intl.get('RequisiteIndex.status')}</li>
                <li>{intl.get('responsible')}</li>
                {oNorm && oNorm.norm_bdeadlineactive ? <li>{intl.get('Requisite.deadline')}</li> : ''}
                <li></li>
              </ul>
            </div>

            <hr />

            <div className="body-table">
              <SortableList
                useDragHandle
                helperClass="tab"
                helperContainer={this.containerEl}
                transitionDuration={0}
                distance={10}
                onSortEnd={this.onSortEnd}
              >
                <ul ref={el => (this.containerEl = el)}>
                  {(aFiltredRequisites.length ? aFiltredRequisites : aRequisites).map((oRequisite, i) => (
                    <SortableItem
                      value={oRequisite.req_nid}
                      helperClass={'selected'}
                      key={`dragItemF-${i}-${oRequisite.req_nid}`}
                      index={i}
                    >
                      <li
                        itemID={oRequisite.req_nid}
                        className={`${oRequisite.open ? 'open' : ''} ${this.getFeaturedClassName(oRequisite)}`}
                        key={`req${oRequisite.req_nid}`}
                        role="button"
                        tabIndex="0"
                        onKeyPress={evt => {
                          evt.stopPropagation();
                          this.loadChild(evt, oRequisite);
                        }}
                        onClick={evt => {
                          evt.stopPropagation();
                          this.loadChild(evt, oRequisite);
                        }}
                      >
                        <DragHandle visibility={true} />

                        {this.renderRequisiteTitleDescription(oRequisite, 0)}

                        <div className="optionsContainerTable">
                          <span
                            onClick={e => {
                              e.stopPropagation();
                              this.openContextMenu(e);
                            }}
                            className={`status insidespan status-${oRequisite.stat_nid} ${oRequisite.req_etitletype === 'T' ? 'no-visible' : ''
                              }`}
                          >
                            {this.getStatusName(oRequisite.stat_nid) ?? ''}

                            {this.renderContextMenu(oRequisite)}
                          </span>

                          <span className="responsible">
                            {oRequisite.user && oRequisite.req_etitletype !== 'T' ? oRequisite.user.user_cname : ''}
                          </span>

                          {oNorm && oNorm.norm_bdeadlineactive ? (
                            <span
                              className={`deadline${this.validateLates(oRequisite.req_ddeadline) &&
                                oRequisite.stat_nid === REQUISITE_NOT_FINISHED_VALUE
                                ? ' late'
                                : ''
                                }`}
                            >
                              <span>
                                {oRequisite.req_ddeadline && oRequisite.req_etitletype !== 'T'
                                  ? moment.parseZone(oRequisite.req_ddeadline).format('DD/MM/YYYY')
                                  : ''}
                              </span>
                            </span>
                          ) : (
                            ''
                          )}
                          <span
                            className={`${oRequisite.notConformRequisite === 1
                              ? 'updatedAt mobile480-hide noCompliaceIcon'
                              : 'updatedAt mobile480-hide'
                              }`}
                          >
                            {oRequisite.req_dedited && oRequisite.req_etitletype !== 'T'
                              ? ` ${intl.get('RequisiteIndex.atualizado_em', {
                                cDate: moment
                                  .parseZone(oRequisite.req_dedited)
                                  .tz('America/Sao_Paulo')
                                  .format('DD/MM/YYYY')
                              })}`
                              : ' '}
                          </span>

                          <span
                            className={`${oRequisite.notConformRequisite === 1
                              ? 'optionsHover mobile480-hide noCompliaceIconVisible'
                              : 'optionsHover mobile480-hide noCompliaceIconHide'
                              }`}
                          >
                            {this.renderRequisiteMenu(oRequisite, oNorm)}
                          </span>
                          {oRequisite.notConformRequisite === 1 && (
                            <i
                              tooltiptext={intl.get('RequisiteIndex.nao_conforme')}
                              className="non-compliance"
                              onClick={e => {
                                const audi_nid = oRequisite.audi_nid ?? null;
                                if (
                                  audi_nid &&
                                  (can('admin', 'quality-panel') || can('access-auditmanagement', 'quality-panel'))
                                ) {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  history.push({
                                    pathname: `/requisite-audit/${audi_nid}`,
                                    state: { nInitialRequisiteID: oRequisite.req_nid }
                                  });
                                }
                              }}
                            ></i>
                          )}
                        </div>
                        {this.renderChilds(oRequisite, 1)}
                      </li>
                    </SortableItem>
                  ))}
                </ul>
              </SortableList>
            </div>
          </div>
        )}
      </Page>
    );
  }
}

export default RequisiteIndex;
