import React, { useEffect, useRef, useState } from 'react';
import PlayVideoTutorialIcon from '../../assets/icons/PlayVideoTutorialIcon';
import PlayVideoIconSquare from '../../assets/icons/PlayVideoIconSquare';
import styles from './index.module.scss'
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';

import { createPortal } from 'react-dom';
import CloseIcon from '../../assets/icons/CloseIcon';
import Axios from '../../config/Axios';
import imgfLoading from '../../assets/img/preloader-black.gif';
import { Spinner } from 'react-bootstrap';
import NoVideos from '../../assets/img/no-tutorials-video.svg';


const RcmpListVideos = React.forwardRef((props, ref) => {
  const { aListVideos, oVideoPlayer, fnHandleVideoTutorial } = props;

  if(aListVideos?.length === 0){
    return(
      <div className={styles.noVideos}>

        <img src={NoVideos} />
        <span>Por enquanto não há nenhum vídeo por aqui, mas logo terá. Fique de olho!</span>
      </div>
    )
  }
  return (
    <>
      <div className={styles.leftSide}>
        <ul className={styles.videosList}>
          <li className={styles.videoItem}>
            <div className={styles.videosListButtons}>
              {aListVideos.map((oVideo, index) => (

                <div className={oVideo.vt_nid === oVideoPlayer?.id ? styles.videoButtomSelected : styles.videoButtom} key={index} onClick={() => fnHandleVideoTutorial(oVideo)}>
                  <div>
                    <PlayVideoIconSquare className={styles.videoButtomIcon} color={oVideo.vt_nid === oVideoPlayer?.id && "#FFFFFF"} />
                  </div>
                  <span>{oVideo.vt_ctitle}</span>
                </div>
              ))}
            </div>

          </li>
        </ul>
      </div>
      <div className={styles.rightSide}>
        {/* <div className={styles.videoPlayer}> */}
        <div id='video-player-tutorial' className={styles.videoPlayer} ref={ref} />
        {/* <PlayVideoIconSquare width={"70"} height={"70"} borderWeight={"1.5"} className={styles.videoButtomIcon} /> */}
        <span>{oVideoPlayer?.title}</span>
      </div>
      {/* </div> */}
    </>
  )
});

function RcmpModalVideosList(props) {
  const { bIsOpen, fnOnClose, oListVideosRes, fnIncrementView, aVideoList} = props;
  const modalOverflowContent = document.getElementById('modal-overflow-content');
  const modalContentOverflow = document.getElementById('modalOverflowContentContainer');

  if (modalOverflowContent) {
    modalOverflowContent.style.alignItems = 'flex-start';
    modalOverflowContent.style.justifyContent = 'space-between';
    modalOverflowContent.style.paddingBottom = '0';
    modalContentOverflow.style.paddingBottom = '0';
  }

  const [bIsCouintedVideoPlayer, fnSetBIsCouintedVideoPlayer] = useState(false);
  const [oVideoPlayer, fnSetOVideoPlayer] = useState({});
    const [bIsFirstClick, fnBIsFirstClick] = useState(false)
  const videoPlayerConatinerRef = useRef(null);

  useEffect(() =>{
    if(bIsCouintedVideoPlayer && oVideoPlayer && !bIsFirstClick){
        fnIncrementView(oVideoPlayer.id, true)
        fnSetBIsCouintedVideoPlayer(false)
        fnBIsFirstClick(true)
    }
}, [bIsCouintedVideoPlayer, bIsFirstClick, oVideoPlayer])


useEffect(() => {
  if (!bIsOpen || !oVideoPlayer.videoId) return;

  let player;
  const videoId = oVideoPlayer.videoId;

  const loadVideo = () => {
      if (player) {
          // Carrega um novo vídeo sem recriar o player
          player.loadVideoById(videoId);
      } else {
          // Inicializa o player pela primeira vez
          player = new window.YT.Player(videoPlayerConatinerRef.current, {
              videoId,
              events: {
                  onStateChange: onPlayerStateChange,
              },
          });
      }
  };
  
  const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.PLAYING) {
          fnSetBIsCouintedVideoPlayer(true)
      }
  };

  if (!window.YT || !window.YT.Player) {
      // Carrega a API apenas uma vez
      const script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      script.async = true;
      script.onload = () => {
          window.onYouTubeIframeAPIReady = loadVideo;
      };
      document.body.appendChild(script);
  } else {
      loadVideo(); // Se a API já estiver carregada, apenas carrega o vídeo
  }

  return () => {
      if (player) {
          player.destroy(); // Limpa o player ao desmontar
      }
  };
}, [bIsOpen, oListVideosRes, oVideoPlayer]);

  useEffect(() => {
    if(!bIsOpen){
      const modalElement = document.getElementById('modal-video-tutorials');
      if (modalElement) {
        document.body.removeChild(modalElement);
      }
    }
  })
  function fnHandleVideoTutorial(oData){
    
    const oDataFormated = {
        videoId: oData.vt_clink.split("watch?v=")[1],
        title: oData.vt_ctitle,
        id: oData.vt_nid
    }
   
    
    fnSetOVideoPlayer(prev => {
        if(!prev){
            return oDataFormated
        }
        if(prev.videoId === oDataFormated.videoId){
            return prev
        }
        if(prev.videoId !== oDataFormated.videoId){
            const playerElement = document.getElementById('video-player');
            if (playerElement) {
                playerElement.innerHTML = ''; // Remove the existing iframe
            }
            return oDataFormated
        }
    })
    fnBIsFirstClick(false)
    fnSetBIsCouintedVideoPlayer(false)
}

  if(!bIsOpen) return null;

  return createPortal(
    <div className={styles.modalOverlay} id="modal-video-tutorials">
      <div bIsOpen={bIsOpen}>
        <div className={styles.headerModal}>
          <p>{intl.get("modal_videos_tutorials.title")}</p>
          <CloseIcon onClick={fnOnClose} />
        </div>
        <div className={styles.modalContainer}>
          <div className={styles.modalContent}>
            {oListVideosRes === undefined ? (
               <div className={styles.loadingArea}>
               <img src={imgfLoading} alt="loading" />
               </div>
            ) : (
              <RcmpListVideos aListVideos={aVideoList} ref={videoPlayerConatinerRef} oVideoPlayer={oVideoPlayer} fnHandleVideoTutorial={fnHandleVideoTutorial}/>
            )}
            
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
}

function RcmpModalVideosTutorials(props) {
  const { className } = props
  const [bOpenVideoPlayer, setBOpenVideoPlayer] = useState(false);
  const [bIsRequestDone, setBIsRequestDone] = useState(undefined);
  const [aVideoList, fnSetAVideoList] = useState([]);
  const cPath = props.history.location.pathname
  const aModules = {
    "/quality-panel": "1",
    "/audit-management": "3",
    "/folder": "4",
  }


  useEffect(() => {
    if(!bOpenVideoPlayer) return
    (async() => {
        try{
          const response = await Axios.get(`/video-tutorial/${aModules[cPath]}`)
          if(response.status === 200){
          fnSetAVideoList(response?.data?.data)
          setBIsRequestDone(response.data)
        }
          
        }catch(oError){
      console.error(oError)
    }
        
    })()
  }, [cPath, bOpenVideoPlayer])	

async function fnIncrementView(cVideoId, bCanCount) {
  if(bCanCount){
    try{
      const response = await Axios.post(`/video-tutorial/${cVideoId}/add-view-counter`)
    return response
    }catch(oError){
      console.error(oError)
    }
  }
  
}

  function fnHandleOpenVideoPlayer() {
      setBOpenVideoPlayer(true);
  }
  function fnHandleCloseVideoPlayer() {
      setBOpenVideoPlayer(false);
      setBIsRequestDone(undefined)
  }

  const cClass = className ? `${styles.modalVideosTutorials} ${className}` : styles.modalVideosTutorials;
  return (
      <div className={cClass}>
          <PlayVideoTutorialIcon
              onClick={() => fnHandleOpenVideoPlayer()}
          />
          {bOpenVideoPlayer && (
              <RcmpModalVideosList 
              bIsOpen={bOpenVideoPlayer} 
              fnOnClose={fnHandleCloseVideoPlayer} 
              oListVideosRes={bIsRequestDone} 
              fnIncrementView={fnIncrementView} 
              aVideoList={aVideoList}
              
              />
          )}
      </div>
  )
}
export default withRouter(RcmpModalVideosTutorials);