import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import axios from 'axios';
import intl from 'react-intl-universal';
import { InputPassword } from '../../components/InputPassword';
import Axios from '../../config/Axios';
import imgfLoading from '../../assets/img/preloader-black.gif';
import { BrandImage } from '../../config/ApperanceContext';

class RegisterAccept extends Component {
  constructor(oProps) {
    super(oProps);

    document.body.classList.add('w-bkg');

    this.state = {
      "cToken": oProps.match.params.cToken,
      "oUser": {},
      user_cpassword: '',
      "bHasAlert": false,
      "aMsgErrors": [''],
      "cAlertType": 'error',
      "bIsSending": false,
      "bLoad": true
    };
  }

  componentDidMount = () => {
    const { cToken } = this.state;
    axios
      .get(`/customer/register/${cToken}`)
      .then((oResponse) => {
        this.setState({
          "oUser": oResponse.data.oUser,
          "bLoad": false
        });
      })
      .catch((oError) => {
        const errorMessage = oError.response.data.cError;
        this.setState({
          "bLoad": false,
          "aMsgErrors": [errorMessage],
          "bHasAlert": true,
          "bIsSending": true
        });

        if (errorMessage.includes("link_expirado")) {
          this.redirectTo('/pre-register'); // Redireciona apenas neste caso
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    const { cToken, oUser, user_cpassword } = this.state;
    Axios.post(`customer/accept/${cToken}`, {
      user_cemail: oUser.user_cemail,
      user_cpassword: user_cpassword
    }).then(oRes => {
      this.setState({
        "cAlertType": 'success',
        "aMsgErrors": [oRes.data],
        "bHasAlert": true,
        "bIsSending": true
      })
    }).catch((oError) => {
      const errorMessage = oError.response.data.cError;
      this.setState({
        "cAlertType": 'error',
        "aMsgErrors": [errorMessage],
        "bHasAlert": true,
        "bIsSending": true
      });

      if (errorMessage.includes("link_expirado")) {
        this.redirectTo('/pre-register'); // Redireciona apenas neste caso
      }
    })
  }

  redirectTo(path) {
    setTimeout(() => {
      window.location.href = path;
    }, 5000);  // Redireciona após 5 segundos
  }

  render() {
    const { aMsgErrors, bHasAlert, cAlertType, cToken, user_cpassword, oUser, bIsSending, bLoad } = this.state;
    return (
      <div>
        {bLoad ? (
          <div className="centered-text">
            <img alt={intl.get('carregando')} src={imgfLoading} />
          </div>
        ) : (
          <section id="register" loading={bLoad ? 1 : 0}>
            <div className="wrapper-login">
              <header>
                <div className="logo">
                  <BrandImage cFileName="logo-text-134x75.png" bHasTitleAlt />
                </div>
                <h1>{intl.get('RegisterAccept.titulo')}</h1>
              </header>
              <main className="form">
                <form className="form-horizontal" onSubmit={this.handleSubmit}>
                {bHasAlert && (
                  <div className={`alert ${cAlertType}`}>
                    {aMsgErrors.map((cMsg) => (
                      cMsg.includes("link_expirado") ? (
                        <p key={Math.random()}>{intl.get('RegisterAccept.invalido_ou_expirado')}</p>
                      ) : (
                        <p key={Math.random()}>{cMsg}</p>
                      )
                    ))}
                  </div>
                )}
                  {cToken && (
                    <div>
                      <Input
                        type="text"
                        name="user_cemail"
                        id="user_cemail"
                        disabled
                        className="disabled"
                        maxLength="250"
                        required
                        value={oUser.user_cemail || ''}
                      />

                      <InputPassword
                        id="user_cpassword"
                        name="user_cpassword"
                        value={user_cpassword}
                        onChange={this.handleChange} />
                      <Button disabled={bIsSending} type="submit">
                        {intl.get('RegisterAccept.aceitar')}
                      </Button>
                    </div>
                  )}
                  <a href="/login" className="btn alternate" style={{ float: 'left', textAlign: 'center' }}>
                    {intl.get('voltar')}
                  </a>
                </form>
              </main>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default RegisterAccept;
