import React, { useState, useEffect } from 'react';
import Axios from '../../config/Axios';
import './styles/documentaccess.scss'; // Certifique-se de que o caminho do arquivo esteja correto
import Login from '../login/Login';
import { BrandTitle } from '../../config/ApperanceContext';

const DocumentAccess = ({ match }) => {
  const { hash } = match.params;
  const [document, setDocument] = useState(null);
  const [error, setError] = useState('');
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [showRestrictedMessage, setShowRestrictedMessage] = useState(false);

  useEffect(() => {
    Axios.get(`/document/share/${hash}`)
      .then(response => setDocument(response.data))
      .catch(err => {
        if (err.response?.status === 403) {
          const { share_link, sHash } = err.response.data;
          localStorage.setItem('share_link', share_link);
          localStorage.setItem('sHash', sHash);
          if (localStorage.getItem("cStorageToken") && localStorage.getItem("cStorageToken").length > 0) {
            window.location.href = share_link;
          }
          setShowRestrictedMessage(true);
          setTimeout(() => {
            setIsLoginVisible(true);
            setShowRestrictedMessage(false);
          }, 5000); // 5 seconds delay before showing the login screen
        } else {
          setError(err.response?.data?.message || 'Problema com código de acesso ou documento inexistente.');
        }
      });
  }, [hash]);

  const handleLoginSuccess = () => {
    const storedHash = localStorage.getItem('sHash');
    Axios.get(`/document/share/${storedHash}`)
      .then(response => {
        setDocument(response.data);
        setIsLoginVisible(false);
      })
      .catch(err => setError(err.response?.data?.message || 'Problema com código de acesso ou documento inexistente.'));
  };

  return (
    <div className="body-background">
      {showRestrictedMessage ? (
        <div className="error-message">
          <div>
            <h2>Documento restrito</h2>
            <p>Você precisa estar logado no sistema para acessar este documento. Redirecionando em 5 segundos...</p>
          </div>
        </div>
      ) : isLoginVisible ? (
        <Login onSuccess={handleLoginSuccess} />
      ) : (
        document ? (
          <div className="document-view">
            <header>
              <div className="logo">
                {document.aCustomer.cust_clogo && <img src={document.aCustomer.cust_clogo} alt={document.aCustomer.cust_cname} />}
              </div>
              <div className="company-info">
                <h1>{document.aCustomer.cust_cname}</h1>
                <div className="title"><strong>{document.aDocument.doc_ctitle}</strong></div>
              </div>
            </header>
            <main>
              <div className="details">
                <div><strong>Responsável:</strong> {document.responsible_name}</div>
                <div><strong>Redator:</strong> {document.writer_name}</div>
                <div><strong>Revisor:</strong> {document.reviewer_name}</div>
                <div><strong>Versão:</strong> {document.aVersionLatest.ver_cnumber}</div>
              </div>
              <div className="published-edited">
                <div><strong>Publicado em:</strong> {new Date(document.aVersionLatest.ver_dpublished).toLocaleDateString()}</div>
                <div><strong>Editado em:</strong> {new Date(document.aVersionLatest.ver_drevised).toLocaleDateString()}</div>
              </div>
              <div className="content" dangerouslySetInnerHTML={{ __html: document.aVersionLatest.ver_ctext }} />
            </main>
            <footer>
              <p>
                <a href="/" target="_blank" rel="noopener noreferrer">© <BrandTitle /></a>
              </p>
            </footer>
          </div>
        ) : (
          <div className="error-message">
            <div>
              <h2>Acessando o documento</h2>
              {error && <p>{error}</p>}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default DocumentAccess;
