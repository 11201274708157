import React, { Component } from 'react';
// import { Button } from 'reactstrap';
import _ from 'lodash';
import { BrandImage, oConfigWL } from '../../config/ApperanceContext';

class Site extends Component {
  constructor(oProps) {
    document.body.classList.add('w-bkg', 'site');
    super(oProps);
  }

  render() {
    return (
      <>
        <header style={{ background: '#53b7e8' }} id="header">
          <span title={oConfigWL.cPageTitle} id="logo">
            <BrandImage cFileName="logo-text-white-137x76.png" className="App-logo" bHasTitleAlt />
          </span>
        </header>
      </>
    );
  }
}

export default Site;
