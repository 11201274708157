import React, { createContext, useCallback, useEffect, useState } from 'react';
import Axios from 'axios';

const C_APPEARANCE_SETTINGS_KEY = 'oAppearanceSettings';
const C_DEFAULT_PAGE_TITLE = 'Acredite-se';

const mountChatBot = () => {
  const elWBotScript = document.createElement('script');
  elWBotScript.src = 'https://wbot.chat/index.js';
  elWBotScript.async = true;
  elWBotScript.setAttribute('token', '29397b7b55a12e7965205d430b79ad1f');
  document.body.appendChild(elWBotScript);
}

/**
 * Expõe informações necessárias para permitir uso sem o context.
 */
export const oConfigWL = {
  cPageTitle: '',
};

const AppearanceContext = createContext(null);

const AppearanceProvider = ({ children }) => {
  const [oConfig, setConfig] = useState(null);

  const fetchAppearanceConfig = useCallback(async () => {
    try {
      const { data: oResult } = await Axios.get(`${process.env.REACT_APP_API_BASE_URL}api/domain-identify`);
      // renovar a cada 24 horas
      oResult.nExpiresAt = Date.now() + 24 * 3600 * 1000;
      localStorage.setItem(C_APPEARANCE_SETTINGS_KEY, JSON.stringify(oResult));
      return oResult;
    } catch (oError) {
      return {};
    }
  }, []);

  const getAppearanceConfig = useCallback(async () => {
    const oResult = JSON.parse(localStorage.getItem(C_APPEARANCE_SETTINGS_KEY));
    if (oResult != null && Date.now() < oResult.nExpiresAt) {
      return oResult;
    }
    return await fetchAppearanceConfig();
  }, [fetchAppearanceConfig]);

  const updateAppearanceConfig = useCallback(async () => {
    const oResult = await getAppearanceConfig();
    setConfig(oResult);
  }, [getAppearanceConfig]);

  /**
   * Sincroniza a config global com os dados mais atuais
   */
  const updateGlobalWLConfig = useCallback((oNewConfig) => {
    oConfigWL.cPageTitle = oNewConfig.cPageTitle ?? C_DEFAULT_PAGE_TITLE;
  }, []);

  useEffect(() => {
    updateAppearanceConfig();
  }, [updateAppearanceConfig]);

  useEffect(() => {
    if (oConfig == null) return;
    // NOTA: Certifique-se de que a config global será sincronizada corretamente ao alterar essa função
    updateGlobalWLConfig(oConfig);

    document.title = oConfigWL.cPageTitle;
    const elFavIcon = document.querySelector('link[rel="shortcut icon"]');
    if (elFavIcon) {
      elFavIcon.href = oConfig.cCdnBaseUrl
        ? getBrandImgSrc('favicon.ico')
        : process.env.PUBLIC_URL + '/favicon.ico';
    }
    if (oConfig.cPageTitle == null) {
      mountChatBot();
    }
  }, [oConfig]);


  /**
   * Retorna o caminho a ser utilizado para uma dada logo.
   * @param {string} cFileName nome esperado do arquivo da logo, com extensão. Exemplo: `logo-text-134x75.png`
   * @return {string} caminho ou url da imagem solicitada
   */
  const getBrandImgSrc = useCallback((cFileName) => {
    if (oConfig == null) return ''; // ainda não foi carregado
    const { nWlpnId, cCdnBaseUrl } = oConfig;
    if (!nWlpnId) {
      // string precisa ser hardcoded nesse require, se tentar usar uma constante, quebra
      return require(`../assets/img/logos/${cFileName}`);
    }
    return cCdnBaseUrl + `${nWlpnId}/${cFileName}`;
  }, [oConfig]);

  const oValues = { oConfig, getBrandImgSrc };

  return (
    <AppearanceContext.Provider value={oValues}>
      {children}
    </AppearanceContext.Provider>
  );
};

// Componentes que utilizam o contexto para configurar exibição de acordo com o domínio

/**
 * @param {React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & { cFileName: string, bHasTitleAlt?: boolean}} props
 * @param {string} props.cFileName nome do arquivo da logo na CDN ou na pasta de assets, com extensão
 *                                 > Exemplo: `logo-text-134x75.png`
 * @param {boolean} [props.bHasTitleAlt] se deve incluir atributo alt com o título do sistema
 */
const BrandImage = ({ cFileName, bHasTitleAlt = false, alt, ...props }) => {
  return (
    <AppearanceContext.Consumer>
      {({ getBrandImgSrc, oConfig }) => {
        return (
          <img
            src={getBrandImgSrc(cFileName)}
            alt={(bHasTitleAlt && oConfig != null)
              ? oConfig.cPageTitle ?? C_DEFAULT_PAGE_TITLE
              : alt}
            {...props}
          />
        );
      }}
    </AppearanceContext.Consumer>
  )
};

const BrandTitle = () => {
  return (
    <AppearanceContext.Consumer>
      {({ oConfig }) => {
        if (oConfig == null) {
          return ''; // ainda não foi carregado
        }
        return oConfig.cPageTitle ?? C_DEFAULT_PAGE_TITLE;
      }}
    </AppearanceContext.Consumer>
  )
};

export { AppearanceContext, AppearanceProvider, BrandImage, BrandTitle };
