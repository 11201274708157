import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from '@stardust-ds/react';
import classNames from 'classnames';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import intl from 'react-intl-universal';
import userImg from '../../assets/img/users.svg';
import CardHeader from '../default-v2/CardHeader';
import Axios from '../../config/Axios';
import imgfSelectDown from '../default-v2/img/select-down.svg';
import imgfSelectUp from '../default-v2/img/select-up.svg';
import { can } from '../../config/Permissions';
import { formatDateToUserTZ } from '../../utils/time';
import oClasses from '../operational-panel/MigrateResponsibilityOperational.module.scss'; // usa o mesmo css
import { SelectResponsibleModal } from '../operational-panel/MigrateResponsibilityOperational';

const MigrateResponsibilityQuality = ({ match }) => {
  const [oConfig, setConfig] = useState(
    JSON.parse(localStorage.getItem('cConfig')) || { cOrderBy: 'cName', cType: 'asc' }
  );
  const [oMigrateModalOptions, setMigrateModalOptions] = useState({ bShow: false });
  const [bIsLoading, setIsLoading] = useState(true);
  const [bHasNextPage, setHasNextPage] = useState(true);
  const [nNextPage, setNextPage] = useState(1);
  const [aUsers, setUsers] = useState([]);
  const [aResponsibilities, setResponsibilities] = useState([]);
  const [aSelectedResps, setSelectedResps] = useState([]);

  const oCheckAllCbRef = useRef(null);
  const oObserverRef = useRef(null);
  const fnLastItemRef = useCallback((elLastItem) => {
    if (bIsLoading) return;
    if (oObserverRef.current) {
      oObserverRef.current.disconnect();
    }
    if (!bHasNextPage) return;

    oObserverRef.current = new IntersectionObserver(([oEntry]) => {
      if (!oEntry.isIntersecting) return;
      getResponsibilities();
    }, {
      threshold: 0,
      rootMargin: '0px 200px 0px 200px',
    });

    if (elLastItem) oObserverRef.current.observe(elLastItem);
  }, [bIsLoading, bHasNextPage]);

  const nUserId = +match.params.nId;

  // atualiza a lista quando altera a ordenação
  useEffect(() => {
    if (bHasNextPage) {
      getResponsibilities(true);
      setSelectedResps([]);
      uncheckCheckAllCb();
      return;
    }
    const aNewResps = [...aResponsibilities];
    sortResponsibilities(aNewResps, oConfig.cOrderBy, oConfig.cType === 'asc');
    setResponsibilities(aNewResps);
  }, [oConfig.cOrderBy, oConfig.cType]);

  useEffect(() => {
    if (!can('admin')) {
      window.location.href = '/';
      return;
    }
    Axios.get(`/user/all`).then((oResponse) => {
      const aTargetUsers = oResponse.data.aUsers
        .filter(item => item.user_nid !== nUserId)
        .map(oUser => ({ value: oUser.user_nid, label: oUser.user_cname }));
      setUsers(aTargetUsers);
    });
  }, []);

  const getResponsibilities = async (bResetList = false) => {
    try {
      setIsLoading(true);

      const oParams = new URLSearchParams({
        orderBy: oConfig.cOrderBy,
        orderDir: oConfig.cType,
        page: bResetList ? 1 : nNextPage
      });

      const oResponse = await Axios.get(`/norm/get-responsibilities/${nUserId}?${oParams.toString()}`);
      /** @type {QualityResponsibility[]} */
      const aNewResponsibilities = oResponse.data.aResponsibilities;
      const bHasMore = oResponse.data.bHasNextPage;

      setHasNextPage(bHasMore);
      if (bHasMore) {
        setNextPage((nCurrPage) => bResetList ? 2 : nCurrPage + 1);
      }
      setResponsibilities(bResetList
        ? aNewResponsibilities
        : [...aResponsibilities, ...aNewResponsibilities]
      );
      uncheckCheckAllCb();
    } catch (oError) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const migrateResponsibilities = async (nNewUser, cPassword, bIsMigrateAll) => {

    // otimização simples para reduzir tamanho da requisição e acelerar a migração:
    // quando todas as responsabilidades forem selecionadas, trata como migrar todas
    const bMigrateAll = bIsMigrateAll
      || (!bHasNextPage && aSelectedResps.length === aResponsibilities.length);

    // quando bMigrateAll === true, campo é ignorado no backend
    const aItems = !bMigrateAll
      ? aResponsibilities
        .filter((oResp) => aSelectedResps.includes(oResp.cIndex))
        .map(({ nId, cType }) => ({ nId, cType }))
      : [];

    await Axios.post(`/norm/migrate-responsibilities/${nUserId}`, {
      nNewUser,
      cPassword,
      aItems,
      bMigrateAll,
    });
    setMigrateModalOptions(() => ({ bShow: false }));
    getResponsibilities(true);
    setSelectedResps([]);
    toast({
      type: 'success',
      title: intl.get('FolderIndex.success_migration'),
    });
  };

  const updateSorting = (cColumn) => {
    setConfig((oPrevConfig) => {
      const oNewConfig = {
        ...oPrevConfig,
        ...(oConfig.cOrderBy !== cColumn
          ? { cOrderBy: cColumn }
          : { cType: oPrevConfig.cType === 'asc' ? 'desc' : 'asc' }
        ),
      };
      localStorage.setItem('cConfig', JSON.stringify(oNewConfig));
      return oNewConfig;
    });
  };

  const handleOpenMigrateModal = (bMigrateAll = false) => {
    setMigrateModalOptions((oPrevOptions) => ({
      ...oPrevOptions,
      bShow: true,
      bMigrateAll,
    }));
  };

  const handleCloseMigrateModal = () => {
    setMigrateModalOptions(() => ({ bShow: false }));
  }

  const handleCheckAll = useCallback((evtClick) => {
    const bIsChecked = evtClick.target.checked;
    const aNewSelectedResps = bIsChecked ? aResponsibilities.map(({cIndex}) => cIndex) : [];
    setSelectedResps(aNewSelectedResps);
  }, [aResponsibilities, setSelectedResps]);

  const uncheckCheckAllCb = () => {
    const elCheckAllCb = oCheckAllCbRef.current;
    if (elCheckAllCb) elCheckAllCb.checked = false;
  }

  const handleResponsibilityCheck = (evtClick, oResponsibility) => {
    const bIsChecked = evtClick.target.checked;
    const { cIndex } = oResponsibility;
    if (bIsChecked) {
      setSelectedResps((cIdxs) => [...cIdxs, cIndex]);
    } else {
      setSelectedResps((cIdxs) => cIdxs.filter((cIdxs) => cIdxs !== cIndex));
      uncheckCheckAllCb();
    }
  }

  const aPages = [
    { cName: 'user', cLink: '/user' },
    { cName: 'Nav.quality_panel', cLink: '/quality-panel' },
    { cName: 'User.migrate_responsibilities' }
  ];
  return (
      <Page
        loading={0}
        rcmpBreadcrumb={<Breadcrumb aPages={aPages} />}
        cTitle={intl.get('User.migrate_responsibilities')}
        cImage={userImg}
        rcmpCardHeader={<CardHeader bIsItToInsertGoBackBtn windowHistory />}
        className="v2-document"
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        <div style={{ height: '64vh', overflowY: 'auto' }}>
          <ResponsibilitiesListHeader
            onCheckAll={handleCheckAll}
            onUpdateOrder={updateSorting}
            oConfig={oConfig}
            oCheckAllCbRef={oCheckAllCbRef}
          />
          <ul style={{ display: 'block' }} className="document-list list-shadow list-view">
            {aResponsibilities.map((oResponsibility, nIdx) => (
              <ResponsibilitiesListItem
                key={oResponsibility.cIndex}
                ref={nIdx + 1 === aResponsibilities.length ? fnLastItemRef : null}
                oResponsibility={oResponsibility}
                onCheck={handleResponsibilityCheck}
                bIsChecked={aSelectedResps.includes(oResponsibility.cIndex)}
              />
            ))}
            {bIsLoading && (
              <li className="centered-text">
                <div className="spinner" style={{ width: '50px', height: '50px' }} />
                <br />
                {intl.get('carregando')}
              </li>
            )}
          </ul>
        </div>
        <div className="button-stack">
          <Button
            type="button"
            className="btn break-line-btn"
            onClick={() => handleOpenMigrateModal(true)}
          >
            {intl.get('FolderIndex.migrate')} <br /> {intl.get('User.all')}
          </Button>
          <Button
            type="button"
            className="btn break-line-btn"
            disabled={aSelectedResps.length < 1}
            onClick={() => handleOpenMigrateModal()}
          >
            {intl.get('FolderIndex.migrate')} <br /> {intl.get('FolderIndex.selecteds')}
          </Button>
        </div>
        {oMigrateModalOptions.bShow && (
          <SelectResponsibleModal
            onClose={handleCloseMigrateModal}
            aUsers={aUsers}
            onSubmit={migrateResponsibilities}
            bMigrateAll={oMigrateModalOptions.bMigrateAll}
          />
        )}
      </Page>
  );
};

const ResponsibilitiesListHeader = ({
  onCheckAll = () => {},
  onUpdateOrder = () => {},
  oConfig = {},
  oCheckAllCbRef,
}) => {
  const { cOrderBy, cType: cOrderType } = oConfig;
  const cIcon = cOrderType === 'asc' ? imgfSelectUp : imgfSelectDown;

  return (
    <div className={classNames("filterList", "noselect", oClasses['migrate-list'])}>
      <span role="button" tabIndex="0" className="alt-checkbox-v2">
        <input type="checkbox" onChange={onCheckAll} ref={oCheckAllCbRef} />
      </span>{' '}
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('cName')}
        className={classNames('filterByName-v2', cOrderBy === 'cName' && 'active')}
      >
        {intl.get('Dashboard.requirements')}
        {cOrderBy === 'cName' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}
      </span>
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('bIsActive')}
        className={classNames('filterByPublish', cOrderBy === 'bIsActive' && 'active')}
      >
        {intl.get('status')}
        {cOrderBy === 'bIsActive' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}
      </span>
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('cType')}
        className={classNames('filterByPublish', cOrderBy === 'cType' && 'active')}
      >
        {intl.get('LinkDocument.tipo')}
        {cOrderBy === 'cType' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}
      </span>
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('cLocation')}
        className={classNames('filterByPublish', cOrderBy === 'cLocation' && 'active')}
        style={{ width: '20%' }}
      >
        {intl.get('DocumentPrint.location')}
        {cOrderBy === 'cLocation' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}{' '}
      </span>
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('dUpdated')}
        className={classNames('filterByDue', cOrderBy === 'dUpdated' && 'active')}
      >
        {intl.get('Norm.last_update')}
        {cOrderBy === 'dUpdated' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}{' '}
      </span>
    </div>
  );
};

const ResponsibilitiesListItem = forwardRef(({
  oResponsibility,
  onCheck = () => {},
  bIsChecked,
}, ref) => {


  return (
    <li role="presentation" ref={ref}>
      <h2 className="alt-checkbox-v2 ">
        <input
          type="checkbox"
          onChange={(evtChange) => onCheck(evtChange, oResponsibility)}
          checked={bIsChecked}
        />
      </h2>

      <h2 className="filterByName-v2" title={oResponsibility.cName}>
        {oResponsibility.cName}
      </h2>

      <p className="filterByPublish">
        {oResponsibility.bIsActive ? intl.get('Status.ativo') : intl.get('Status.inativo')}
      </p>

      <p className="filterByPublish">
        {oResponsibility.cType === 'norm'
          ? intl.get('RequisiteIndex.norma')
          : intl.get('Dashboard.requisite')}
      </p>

      <p className="filterByPublish">
        {oResponsibility.cLocation}
      </p>

      <p className="filterByPublish">
        {oResponsibility.dUpdated
          ? formatDateToUserTZ(oResponsibility.dUpdated, 'LL')
          : intl.get('FolderIndex.no_due')}
      </p>
    </li>
  )
});

/**
 * Ordena e retorna a lista de responsabilidades. **Modifica o array passado como parâmetro.**
 * @param {QualityResponsibility[]} aResponsibilities
 * @param {"cType"|"cName"|"bIsActive"|"dUpdated"|"cLocation"|"bIsSingleResponsible"} cColumn
 * @param {boolean} bAscending
 */
const sortResponsibilities = (aResponsibilities, cColumn, bAscending) => {
  const nSign = bAscending ? 1 : -1;

  if (['bIsSingleResponsible', 'bIsActive'].includes(cColumn)) {
    return aResponsibilities.sort((a, b) => nSign * (Number(a[cColumn]) - Number(b[cColumn])) );
  }
  return aResponsibilities.sort((a, b) => nSign * (a[cColumn] ?? '').localeCompare(b[cColumn] ?? ''));
};

/**
 * @typedef {object} QualityResponsibility
 *
 * @property {string} cType
 * @property {string} cIndex
 * @property {number} nId
 * @property {string} cName
 * @property {boolean} bIsActive
 * @property {string} dUpdated
 * @property {boolean} bIsSingleResponsible
 */


export default MigrateResponsibilityQuality;
