import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button, Input, Table } from 'reactstrap';
import Axios from '../../config/Axios';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import CardHeader from '../default-v2/CardHeader';
import { getValidateVoucher } from '../../actions/ActionCoupon';
import ModuleBlock from './components/ModuleBlock';
import './styles/module.scss';
import { can } from '../../config/Permissions';
import AltCheckbox from '../../components/AltCheckbox';
import SweetAlert from 'react-bootstrap-sweetalert';
import ScrollBar from '../../components/ScrollBar';
import BillingData from './BillingData';
import fLoading from '../../assets/img/preloader-black.gif';
import Block from './components/Block';
import moment from 'moment';
import { ThreeSixtyOutlined } from '@material-ui/icons';
import logout from '../../config/Logout';
import imgfConfig from '../default-v2/img/icon-config.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
import { decodeToken } from "react-jwt";
import { toast } from '@stardust-ds/react';

const oLocalStorage = new EncodedLocalStorage();
const moduleDependencies = {
  '3': '1', // mod_nid 3 depende de mod_nid 1
};

export class Module extends Component {
  constructor(oProps) {
    super(oProps);

    this.CONFIGS_KEYS = {
      annual_discount: 'discount_annual_plan',
      total_users: 'default_total_users',
      min_space: 'min_space',
      annual_plan_nid: 'plan_nid_annual',
      monthly_plan_nid: 'plan_nid_monthly',
      superlogica_service_id: 'superlogica_service_id',
      software_basic_value: 'software_basic_value',
      value_per_user: 'value_per_user',
      value_per_gigabyte: 'value_per_gigabyte'
    };

    this.bIsTrial = oLocalStorage.get('bTrial') === 'true';

    this.state = {
      bLoading: true,
      bRedirectToPayment: false,
      aPages: [
        { cName: 'configs', cLink: undefined },
        { cName: 'Module.title', cLink: undefined }
      ],
      bSending: false,
      bIsSendingSubscription: false,
      aModules: [],
      oCustomer: null,
      nCurrentPrice: 0,
      nCurrentTotalSpace: 0,
      nCurrentTotalUsers: 0,
      oCustomerSubscription: null,
      aCustomerModulesIds: [],
      bIsAnyModuleSelected: false,
      nTotalSpace: 0,
      nTotalUsers: 0,
      nPaidValue: 0.0,
      cCoupon: '',
      nDiscount: 0,
      nVoucherId: null,
      bAnnualPlan: true,
      rcmpSweetAlert: null,
      oSnapshot: {
        aModules: null,
        oCustomer: null,
        bLocked: false
      },
      bHasChanged: false,
      nMinSpace: 0,
      nMinUserQuantity: 0
    };
  }

  parseConfigValue = (cKey, cValue) => {
    const parseToIntKeys = [
      this.CONFIGS_KEYS.total_users,
      this.CONFIGS_KEYS.min_space,
      this.CONFIGS_KEYS.software_basic_value,
      this.CONFIGS_KEYS.value_per_user,
      this.CONFIGS_KEYS.annual_plan_nid,
      this.CONFIGS_KEYS.monthly_plan_nid
    ];

    if (cKey == this.CONFIGS_KEYS.annual_discount) {
      return parseFloat(cValue);
    }

    if (parseToIntKeys.includes(cKey)) {
      return parseInt(cValue);
    }

    return cValue;
  };

  componentDidMount = () => {
    const { history } = this.props;

    if (!oLocalStorage.get('bAdmin')) {
      history.push({
        pathname: '/',
        state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
      });
    }
    this.getModuleData();
  };

  createSnapshot = () => {
    const { aModules, oCustomer, oSnapshot, bAnnualPlan, nPaidValue } = this.state;

    if (oSnapshot.bLocked) {
      return;
    }

    this.setState({
      oSnapshot: {
        aModules: aModules.map(oModule => {
          return { ...oModule };
        }),
        oCustomer: { ...oCustomer },
        bLocked: true,
        bAnnualPlan,
        nPaidValue
      }
    });
  };

  getModuleData = () => {
    const { setAlert } = this.props;

    Axios.get('module')
      .then(cResponseToken => {
        const oResponse = decodeToken(cResponseToken.data);
        const aCustomerModulesIds = oResponse.aCustomerModules.map(oCustomerModule => oCustomerModule.mod_nid);
        const aModules = oResponse.aModules.map(oModule => {
          return {
            ...oModule,
            bEnabled: aCustomerModulesIds.length > 0 ? aCustomerModulesIds.includes(oModule.mod_nid) : true
          };
        });
        const nMinSpace = oResponse.nMinSpace ?? 0;
        const nMinUserQuantity = oResponse.nMinUserQuantity ?? 10;
        const nTotalSpace = oResponse.oCustomer.cust_ntotalspace ?? nMinSpace;
        const nTotalUsers = oResponse.oCustomer.cust_ntotalusers ?? nMinUserQuantity;
        this.setState({
          aModules,
          aCustomerModulesIds,
          oCustomer: oResponse.oCustomer,
          bIsAnyModuleSelected: aModules.some(module => module.bEnabled),
          nTotalSpace,
          nTotalUsers,
          nMinSpace,
          nMinUserQuantity,
          nCurrentPrice: oResponse.oPlan.plan_nnet_price,
          nCurrentTotalSpace: nTotalSpace,
          nCurrentTotalUsers: nTotalUsers
        });

        this.getCustomerSubscription(oResponse.oCustomer);

        if (typeof oResponse.aConfigs !== 'undefined' && oResponse.aConfigs.length > 0) {
          oResponse.aConfigs.map(oItem => {
            this.setState({ [oItem.sysc_cid]: this.parseConfigValue(oItem.sysc_cid, oItem.sysc_cvalue) });
          });
          if (nMinUserQuantity && this.state.nTotalUsers === 0) {
            this.setState({ nTotalUsers: nMinUserQuantity });
          }
          if (nMinSpace && this.state.nTotalSpace === 0) {
            this.setState({ nTotalSpace: nMinSpace });
          }
          if (this.CONFIGS_KEYS.annual_plan_nid in this.state && this.CONFIGS_KEYS.monthly_plan_nid in this.state) {
            this.setState({
              bAnnualPlan: this.state.oCustomer.cust_nplanid === this.state[this.CONFIGS_KEYS.annual_plan_nid]
            });
          }
        }

        this.setState({ nPaidValue: this.recalculatePaidValue() });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      })
      .finally(() => {
        this.setState({ bLoading: false, bSending: false });
        this.createSnapshot();
      });
  };

  getCustomerSubscription = oCustomer => {
    if (oCustomer) {
      Axios.get(`customer/subscription`)
        .then(oResponse => {
          this.setState({
            oCustomerSubscription: oResponse.data
          });
        })
        .catch(oErrorResponse => {
          if (oErrorResponse.response?.status === 404) {
            this.setState({
              oCustomerSubscription: null
            });
          }
        })
        .finally(() => {
          this.setState({ bLoading: false, bSending: false });
        });
    }
  };

  hideAlert = () => {
    this.setState({
      rcmpSweetAlert: null
    });
  };

  changeWaitingAlert = () => {
    const { bIsSendingSubscription } = this.state;
    if (bIsSendingSubscription) {
      this.setState({
        bIsSendingSubscription: false,
        rcmpSweetAlert: (
          <SweetAlert
            warning
            customIcon={fLoading}
            title={intl.get('BillingData.subscription_await_title')}
            onConfirm={this.hideAlert}
            showConfirm={true}
          >
            {intl.get('BillingData.subscription_too_long_waiting')}
          </SweetAlert>
        )
      });
    }
  };

  showWaitingAlert = () => {
    this.setState({
      rcmpSweetAlert: (
        <SweetAlert
          custom
          customIcon={fLoading}
          title={intl.get('BillingData.subscription_await_title')}
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          {intl.get('BillingData.subscription_await')}
        </SweetAlert>
      )
    });
  };

  openLinkInNewTab = cLink => {
    const oClientAreaWindow = window.open(cLink, '_blank', 'noopener,noreferrer');
    if (oClientAreaWindow) oClientAreaWindow.opener = null;
  };

  closeAlertAndRedirectToPayment = cPaymentLink => {
    const { bRedirectToPayment } = this.state;
    if (bRedirectToPayment) {
      this.hideAlert();
      this.setState({ bRedirectToPayment: false });
      this.openLinkInNewTab(cPaymentLink);
    } else {
      this.hideAlert();
    }
  };

  showSuccessSubscriptionAlert = cPaymentLink => {
    let bRedirectToPayment = false;
    let cContent = intl.get('BillingData.subscription_success_noredirect');
    if (cPaymentLink.length > 0) {
      bRedirectToPayment = true;
      cContent = intl.get('BillingData.subscription_success');
    }

    this.setState({
      rcmpSweetAlert: (
        <SweetAlert
          success
          title={intl.get('BillingData.subscription_success_title')}
          onConfirm={() => this.closeAlertAndRedirectToPayment(cPaymentLink)}
          timeout={1000 * 7}
        >
          {cContent}
        </SweetAlert>
      ),
      bRedirectToPayment
    });
  };

  showSubscriptionErrorAlert = cMessage => {
    this.setState({
      rcmpSweetAlert: (
        <SweetAlert
          type="error"
          title={intl.get('BillingData.signup_error_title')}
          onConfirm={this.hideAlert}
          onCancel={this.hideAlert}
          closeOnClickOutside={true}
        >
          {cMessage ?? ''}
        </SweetAlert>
      )
    });
  };

  getCustomerData = oBillingData => {
    const {
      aModules,
      nTotalUsers,
      nTotalSpace,
      nPaidValue,
      bAnnualPlan,
      nDiscount,
      nVoucherId,
      plan_nid_annual,
      plan_nid_monthly
    } = this.state;

    const aSelectedModules = aModules.filter(oModule => oModule.bEnabled);
    const nTotalAmount = bAnnualPlan ? parseInt((nPaidValue / 12).toFixed(2) * 100) : parseInt(nPaidValue * 100);

    const data = {
      cust_ctype: oBillingData.cust_ctype,
      cust_cname: oBillingData.cust_cname,
      cust_cfantasyname: oBillingData.cust_cfantasyname,
      cust_ccnpj: oBillingData.cust_ccnpj,
      cust_cIE: oBillingData.cust_cIE,
      cust_cIM: oBillingData.cust_cIM,
      cust_etaxframework: oBillingData.cust_etaxframework,
      cust_ccpf: oBillingData.cust_ccpf,
      cust_cpostalcode: oBillingData.cust_cpostalcode,
      cust_cstreet: oBillingData.cust_cstreet,
      cust_cnumber: oBillingData.cust_cnumber,
      cust_cdistrict: oBillingData.cust_cdistrict,
      cust_ccity: oBillingData.cust_ccity,
      cust_cstate: oBillingData.cust_cstate,
      cust_ccountry: oBillingData.cust_ccountry ?? 'Brasil',
      cust_cphone: oBillingData.cust_cphone,
      cust_cemail: oBillingData.cust_cemail,
      cust_ntotalusers: nTotalUsers,
      cust_ntotalspace: nTotalSpace,
      cust_nplanid: bAnnualPlan ? plan_nid_annual : plan_nid_monthly,
      sig_npayamount: nTotalAmount,
      sig_bisannual: bAnnualPlan,
      sig_amodulesnid: aSelectedModules.map(module => module.mod_nid),
      vch_ndiscount: parseInt(nDiscount * 100),
      vch_nid: nVoucherId,
    };
    return data;
  };

  getErrorMessage = oError => {
    if (
      !oError ||
      !oError.response ||
      !oError.response.data ||
      (!oError.response.data.errors && !oError.response.data.message)
    ) {
      return 'Erro desconhecido';
    }

    if (oError.response.data.errors) {
      const oErrors = oError.response.data.errors;
      const aErrors = [];
      for (const cKey in oErrors) {
        aErrors.push(oErrors[cKey]);
      }
      return aErrors.join('\n');
    }

    return oError.response.data.message;
  };

  validateCoupon = () => {
    const { cCoupon, nPaidValue } = this.state;

    getValidateVoucher(cCoupon).then((response) => {
      toast({
        type: 'success',
        title: intl.get('Module.coupon_valid')
      });

      this.setState({
        nVoucherId: response.data.vch_nid,
        nDiscount: nPaidValue * (response.data.vch_ndiscount / 100)
      });
    }).catch(oError => {
      toast({
        type: 'error',
        title:intl.get('Module.coupon_invalid'),
        description: oError?.response?.data?.message ?? ''
      });
      this.setState({
        nVoucherId: null,
        nDiscount: 0
      });
    }).finally(() => {
      document.getElementById('nPaidValue').value = this.changePrice();
    });
  };

  couponChange = evt => {
    this.setState({ cCoupon: evt.target.value });
  };

  changeSubscription = oBillingData => {
    const { oCustomer } = this.state;
    const data = this.getCustomerData(oBillingData);

    this.setState({ bIsSendingSubscription: true });
    setTimeout(this.changeWaitingAlert, 30000);
    this.showWaitingAlert();

    Axios.put(`customer/subscription-change`, data)
      .then(oResponse => {
        if (this.state.bIsSendingSubscription) {
          this.getModuleData();
          this.getCustomerSubscription(oCustomer);
          this.showSuccessSubscriptionAlert(oResponse.data.cAuthenticatedLink);
        }
      })
      .catch(oError => {
        if (this.state.bIsSendingSubscription) {
          this.showSubscriptionErrorAlert(this.getErrorMessage(oError));
        }
      })
      .finally(() => {
        this.setState({ bIsSendingSubscription: false });
      });
  };

  createSubscription = oBillingData => {
    const { oCustomer } = this.state;
    const data = this.getCustomerData(oBillingData);

    this.setState({ bIsSendingSubscription: true });
    setTimeout(this.changeWaitingAlert, 30000);
    this.showWaitingAlert();

    Axios.post(`customer/signup`, data)
      .then(oResponse => {
        if (this.state.bIsSendingSubscription) {
          this.getModuleData();
          this.getCustomerSubscription(oCustomer);
          this.showSuccessSubscriptionAlert(oResponse.data.cAuthenticatedLink);
        }
      })
      .catch(oError => {
        if (this.state.bIsSendingSubscription) {
          this.showSubscriptionErrorAlert(this.getErrorMessage(oError));
        }
      })
      .finally(() => {
        this.setState({ bIsSendingSubscription: false });
      });
  };

  showBillingData = funcCallback => {
    const { oCustomer, oCustomerSubscription } = this.state;
    this.setState({
      rcmpSweetAlert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow ScrollBar"
          title=""
          showConfirm={false}
          onConfirm={this.hideAlert}
        >
          <div className="head-modal">
            {intl.get('BillingData.title')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <BillingData
              onSuccess={oData => {
                funcCallback(oData);
              }}
              closeModal={this.hideAlert}
              oCustomer={oCustomer}
              oCustomerSubscription={oCustomerSubscription}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  showDependencyConflictAlert = aSelectedIds => {
    const { aModules } = this.state;
    this.setState({
      rcmpSweetAlert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow ScrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={true}
        >
          <div className="head-modal">
            {intl.get('BillingData.modules_dependency')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <div className="module-dependency-alert">
              <Table className="module-dependency-table">
                <thead>
                  <tr>
                    <th>{intl.get('BillingData.modules')}</th>
                    <th>{intl.get('BillingData.dependencies')}</th>
                  </tr>
                </thead>
                <tbody>
                  {aModules.map(oModule => (
                    <tr
                      className={
                        !this.checkIfAllParentsAreSelected(oModule.mod_aparents, aSelectedIds)
                          ? 'incorrect-selection'
                          : ''
                      }
                      key={`module-${oModule.mod_nid}`}
                    >
                      <td>{oModule.mod_cname}</td>
                      <td>
                        <ul>
                          {oModule.mod_aparents.map(oParentModule => (
                            <li key={`parent-module-${oParentModule.mod_nid}`}>{oParentModule.mod_cname}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="alert-description">
                <p>{intl.get('BillingData.dependency_alert_description_1')}</p>
                <p>{intl.get('BillingData.dependency_alert_description_2')}</p>
              </div>
            </div>
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  getModuleChangesList = () => {
    const { aModules, oSnapshot } = this.state;

    const aSelectedModules = aModules.filter(oModule => oModule.bEnabled);
    const aSnapshotSelectedModules = oSnapshot.aModules.filter(oSnapshotModule => oSnapshotModule.bEnabled);

    const aAddedModules = [];
    const aRemovedModules = [];

    Object.keys(aSelectedModules).map(cKey => {
      let bFound = false;
      Object.keys(aSnapshotSelectedModules).map(cKey2 => {
        if (aSelectedModules[cKey].mod_nid === aSnapshotSelectedModules[cKey2].mod_nid) {
          bFound = true;
        }
      });

      if (!bFound) {
        aAddedModules.push(aSelectedModules[cKey]);
      }
    });

    Object.keys(aSnapshotSelectedModules).map(cKey => {
      let bFound = false;
      Object.keys(aSelectedModules).map(cKey2 => {
        if (aSnapshotSelectedModules[cKey].mod_nid === aSelectedModules[cKey2].mod_nid) {
          bFound = true;
        }
      });

      if (!bFound) {
        aRemovedModules.push(aSnapshotSelectedModules[cKey]);
      }
    });

    return (
      <>
        {aAddedModules.length > 0 &&
          Object.keys(aAddedModules).map(cKey => {
            return <li className="module-changeplan-adding">{aAddedModules[cKey].mod_cname}</li>;
          })}
        {aRemovedModules.length > 0 &&
          Object.keys(aRemovedModules).map(cKey => {
            return <li className="module-changeplan-removing">{aRemovedModules[cKey].mod_cname}</li>;
          })}
      </>
    );
  };

  showChangePlanConfirmation = () => {
    const { nTotalUsers, nPaidValue, bAnnualPlan, oSnapshot, nTotalSpace } = this.state;

    this.setState({
      rcmpSweetAlert: (
        <SweetAlert
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={intl.get('Module.change_plan')}
          onConfirm={() => {
            this.showBillingData(this.changeSubscription);
          }}
          onCancel={this.hideAlert}
          style={{ top: '35%' }}
        >
          <div className="confirmation-alert">
            {this.modulesChanged() && (
              <>
                <h5>{intl.get('Module.module_change_title')}:</h5>
                <ul>{this.getModuleChangesList()}</ul>
              </>
            )}
            {oSnapshot.oCustomer.cust_ntotalusers !== nTotalUsers && (
              <>
                <h5>{intl.get('Module.module_selected_users')}: </h5>
                <span>
                  {intl.get('of')} {oSnapshot.oCustomer.cust_ntotalusers} {intl.get('Request.para')} {nTotalUsers}{' '}
                  {intl.get('Module.users')}
                </span>
              </>
            )}
            {oSnapshot.oCustomer.cust_ntotalspace !== nTotalSpace && (
              <>
                <h5>{intl.get('Module.module_extras_space')}: </h5>
                <span>
                  {intl.get('of')} {oSnapshot.oCustomer.cust_ntotalspace} {intl.get('Request.para')} {nTotalSpace} GB
                </span>
              </>
            )}
            <h5>{intl.get('Module.old_value')}: </h5>
            <div className="total-value-container">
              <div className="total-value-input-container">
                <Input type="text" id="nPaidValue-old" readOnly value={`R$ ${oSnapshot.nPaidValue}`} disabled />
                <div className="total-value-period-container">
                  <span className="total-value-period">
                    /{oSnapshot.bAnnualPlan ? intl.get('PlanIndex.year') : intl.get('PlanIndex.month')}
                  </span>
                </div>
              </div>
            </div>
            <h5>{intl.get('Module.new_value')}: </h5>
            <div className="total-value-container">
              <div className="total-value-input-container">
                <Input type="text" id="nPaidValue-new" readOnly value={`R$ ${this.recalculatePaidValue()}`} />
                <div className="total-value-period-container">
                  <span className="total-value-period">
                    /{bAnnualPlan ? intl.get('PlanIndex.year') : intl.get('PlanIndex.month')}
                  </span>
                </div>
              </div>
            </div>
            {nPaidValue < oSnapshot.nPaidValue && (
              <span className="module-value-reduction">{intl.get('Module.value_reduction')}</span>
            )}
          </div>
        </SweetAlert>
      )
    });
  };

  changePrice = () => {
    const { nDiscount } = this.state;

    return `R$ ${(this.recalculatePaidValue() - nDiscount).toFixed(2)}`;
  }

  showSignupConfirmation = () => {
    const { aModules, nTotalUsers, bAnnualPlan } = this.state;

    this.setState({
      rcmpSweetAlert: (
        <SweetAlert
          customClass="modal-edit lg disabled-overflow ScrollBar customer-local"
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title=''
          onConfirm={() => {
            this.showBillingData(this.createSubscription);
          }}
          onCancel={this.hideAlert}
          style={{ top: '35%' }}
        >
          <div className="head-modal">
              {intl.get('Module.signup_confirmation_title')}
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyPress={this.hideAlert}
                onClick={this.hideAlert}
                className="close"
              />
            </div>
          <div className="confirmation-alert">
            <h5>{intl.get('Module.module_list_title')}:</h5>
            <ul>
              {aModules.map(oModule => {
                if (oModule.bEnabled) {
                  return <li key={`selected-module-${oModule.mod_nid}`}>{oModule.mod_cname}</li>;
                }
              })}
            </ul>
            <h5>{intl.get('Module.module_selected_users')}: </h5>
            <span>
              {nTotalUsers} {intl.get('Module.users')}
            </span>
            <h5>{intl.get('Module.coupon')}: </h5>
            <div className="total-value-container">
              <span className="cupom-input-container">
                <Input type="text" id="cCoupon" onChange={this.couponChange} />
              </span>
              <span
                className='icon-button'
                onClick={this.validateCoupon}>
                <span className='button-icon'>Aplicar</span>
              </span>
            </div>

            <h5>{intl.get('Module.total_value')}: </h5>
            <div className="total-value-container">
              <div className="total-value-input-container">
                <Input type="text" id="nPaidValue" readOnly value={`R$ ${(this.recalculatePaidValue())}`}/>
                <div className="total-value-period-container">
                  <span className="total-value-period">
                    /{bAnnualPlan ? intl.get('PlanIndex.year') : intl.get('PlanIndex.month')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  };

  signupShowConfirmation = event => {
    event.preventDefault();

    const { bIsAnyModuleSelected, aModules } = this.state;
    const aSelectedIds = aModules.filter(oModule => oModule.bEnabled).map(oModule => oModule.mod_nid);
    if (!this.checkSelectedModulesDependency()) {
      this.showDependencyConflictAlert(aSelectedIds);
    } else if (bIsAnyModuleSelected) {
      this.showSignupConfirmation();
    }
  };

  signup = () => {
    const { aModules, nTotalSpace, nTotalUsers } = this.state;
    const { setAlert } = this.props;

    this.setState({ bLoading: true, bSending: true });

    Axios.post('module', {
      aModules,
      nTotalSpace: parseInt(nTotalSpace, 0) * 1024,
      nTotalUsers
    })
      .then(() => {
        setAlert('success', intl.get('Module.plan_changed'));
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      })
      .finally(() => {
        this.setState({ bLoading: false, bSending: false });
      });
  };

  changePlan = evtClick => {
    this.setState({ nPaidValue: this.recalculatePaidValue() });
    evtClick.preventDefault();

    const { bIsAnyModuleSelected, aModules } = this.state;
    const aSelectedIds = aModules.filter(oModule => oModule.bEnabled).map(oModule => oModule.mod_nid);
    if (!this.checkSelectedModulesDependency()) {
      this.showDependencyConflictAlert(aSelectedIds);
    } else if (bIsAnyModuleSelected) {
      this.showChangePlanConfirmation();
    }
  };

  handleChangeCheckbox = evt => {
    this.setState(
      {
        [evt.target.name]: evt.target.checked
      },
      () => {
        this.setState({ nPaidValue: this.recalculatePaidValue() });
      }
    );
    this.customerHasChanged(evt.target.name, evt.target.checked);
  };

  handleChange = evt => {
    evt.preventDefault();

    this.setState(
      {
        [evt.target.name]: evt.target.value
      },
      () => {
        this.setState({ nPaidValue: this.recalculatePaidValue() });
      }
    );
    this.customerHasChanged(null, null);
  };

  totalSpaceUp = evt => {
    evt.preventDefault();
    const { nTotalSpace, bIsAnyModuleSelected } = this.state;

    if (!bIsAnyModuleSelected) {
      return;
    }

    const nNewTotalSpace = parseInt(nTotalSpace, 0) + 1;

    this.setState({
      nTotalSpace: nNewTotalSpace,
      nPaidValue: this.recalculatePaidValue('nTotalSpace', nNewTotalSpace)
    });
    this.customerHasChanged('nTotalSpace', nNewTotalSpace);
  };

  totalSpaceDown = evt => {
    evt.preventDefault();
    const { nTotalSpace, nMinSpace, bIsAnyModuleSelected } = this.state;

    if (!bIsAnyModuleSelected) {
      return;
    }

    if (nTotalSpace > nMinSpace) {
      const nNewTotalSpace = parseInt(nTotalSpace, 0) - 1;
      this.setState({
        nTotalSpace: nNewTotalSpace,
        nPaidValue: this.recalculatePaidValue('nTotalSpace', nNewTotalSpace)
      });
      this.customerHasChanged('nTotalSpace', nNewTotalSpace);
    }
  };

  totalUsersUp = evt => {
    evt.preventDefault();
    const { nTotalUsers, bIsAnyModuleSelected } = this.state;

    if (!bIsAnyModuleSelected) {
      return;
    }

    const nNewTotalUsers = parseInt(nTotalUsers, 0) + 1;
    this.setState({
      nTotalUsers: nNewTotalUsers,
      nPaidValue: this.recalculatePaidValue('nTotalUsers', nNewTotalUsers)
    });
    this.customerHasChanged('nTotalUsers', nNewTotalUsers);
  };

  totalUsersDown = evt => {
    evt.preventDefault();
    const { nTotalUsers, bIsAnyModuleSelected, nMinUserQuantity } = this.state;

    if (!bIsAnyModuleSelected) {
      return;
    }

    const nNewTotalUsers = parseInt(nTotalUsers, 0) - 1;
    if (nTotalUsers > nMinUserQuantity) {
      this.setState({
        nTotalUsers: nNewTotalUsers,
        nPaidValue: this.recalculatePaidValue('nTotalUsers', nNewTotalUsers)
      });
      this.customerHasChanged('nTotalUsers', nNewTotalUsers);
    }
  };

  recalculatePaidValue = (cUpdatedField, nValue) => {
    const {
      aModules,
      software_basic_value,
      value_per_gigabyte,
      value_per_user,
      nTotalUsers,
      nTotalSpace,
      bAnnualPlan,
      aCustomerModulesIds,
      nCurrentPrice,
      nCurrentTotalSpace,
      nCurrentTotalUsers
    } = this.state;
    const fAnnualDiscount = this.state[this.CONFIGS_KEYS.annual_discount];
    const MONTHS_PER_YEAR = 12;

    let nTotalUsersUsed = nTotalUsers;
    let nTotalSpaceUsed = nTotalSpace;

    nTotalUsersUsed = cUpdatedField === 'nTotalUsers' ? nValue : nTotalUsers;
    nTotalSpaceUsed = cUpdatedField === 'nTotalSpace' ? nValue : nTotalSpace;

    let nPaidValue = nCurrentPrice;
    aModules.filter(oModule => !aCustomerModulesIds.includes(oModule.mod_nid)).forEach(oModule => {
      if (oModule.bEnabled) {
        nPaidValue += software_basic_value + oModule.mod_nvalue;
      }
    });

    if (nPaidValue === 0) {
      return nPaidValue;
    }

    if (typeof value_per_gigabyte !== 'undefined' && nTotalSpaceUsed !== nCurrentTotalSpace) {
      nPaidValue += value_per_gigabyte * nTotalSpaceUsed;
    }

    if (typeof value_per_user !== 'undefined' && nTotalUsersUsed !== nCurrentTotalUsers) {
      nPaidValue += value_per_user * nTotalUsersUsed;
    }

    if (bAnnualPlan) {
      nPaidValue = nPaidValue * MONTHS_PER_YEAR * (1 - fAnnualDiscount / 100);
      nPaidValue = (nPaidValue / 1200).toFixed(2) * 12;
    } else {
      nPaidValue = nPaidValue / 100;
    }

    return nPaidValue.toFixed(2);
  };

  checkIfAllParentsAreSelected = (aParents, aSelectedIds) => {
    return aParents
      .map(oParentModule => {
        return aSelectedIds.includes(oParentModule.mod_nid);
      })
      .every(value => value);
  };

  checkSelectedModulesDependency = () => {
    const { aModules } = this.state;
    const aSelectedModules = aModules.filter(oModule => oModule.bEnabled);
    const aSelectedIds = aSelectedModules.map(oModule => oModule.mod_nid);

    let bReturn = true;

    aSelectedModules.forEach(oModule => {
      if (oModule.mod_aparents.length > 0 && !this.checkIfAllParentsAreSelected(oModule.mod_aparents, aSelectedIds)) {
        bReturn = false;
      }
    });
    return bReturn;
  };

  checkConstraintAndResetToMinimum = (evt, key, constraint) => {
    evt.preventDefault();
    if (this.state[key] < constraint) {
      this.setState({
        [key]: constraint
      });
    }
  };

  checkMinUserQuantity = (evt, key, nMinUserQuantity) => {
    evt.preventDefault();

    const nNewTotalUsers = parseInt(this.state[key], 0) - 1;
    if (nNewTotalUsers < nMinUserQuantity) {
      this.setState({
        [key]: nMinUserQuantity
      });
    }
  };

  renderModuleValue = () => {
    const { bAnnualPlan, nPaidValue } = this.state;
    return (
      <div className="module-value">
        <h5>
          {intl.get('Module.module_value_title')}
          {bAnnualPlan ? intl.get('PlanIndex.year') : intl.get('PlanIndex.month')}
        </h5>
        <div className="module-value-input-container">
          <div className="module-value-installment-container">
            <span className="module-value-emphasis">
              R${' '}
              {nPaidValue
                .toString()
                .replace(',', '')
                .replace('.', ',')}
            </span>
            <span>&nbsp;/ {bAnnualPlan ? intl.get('PlanIndex.year') : intl.get('PlanIndex.month')}</span>
          </div>
        </div>
      </div>
    );
  };

  modulesChanged = () => {
    const { aModules, oSnapshot } = this.state;
    let bHasChanged = false;

    const aSelectedModules = aModules.filter(oModule => oModule.bEnabled);
    const aSnapshotSelectedModules = oSnapshot.aModules.filter(oSnapshotModule => oSnapshotModule.bEnabled);

    if (aSelectedModules.length === aSnapshotSelectedModules.length) {
      Object.keys(aSelectedModules).map(cKey => {
        let bFound = false;
        Object.keys(aSnapshotSelectedModules).map(cKey2 => {
          if (aSelectedModules[cKey].mod_nid === aSnapshotSelectedModules[cKey2].mod_nid) {
            bFound = true;
          }
        });

        if (!bFound) {
          bHasChanged = true;
        }
      });
    } else {
      bHasChanged = true;
    }
    return bHasChanged;
  };

  customerHasChanged = (cField, cValue) => {
    const { bAnnualPlan, oSnapshot, nTotalSpace, nTotalUsers } = this.state;

    let bTotalSpace = true;
    let bTotalUsers = true;
    let bCorrectPlan = true;

    if (!oSnapshot.oCustomer) {
      return;
    }

    if (
      (cField === 'nTotalSpace' && cValue !== oSnapshot.oCustomer.cust_ntotalspace) ||
      (cField === 'nTotalUsers' && cValue !== oSnapshot.oCustomer.cust_ntotalusers) ||
      (cField === 'bAnnualPlan' && cValue !== oSnapshot.bAnnualPlan)
    ) {
      this.setState({
        bHasChanged: true
      });
      return;
    }

    if (cField !== 'nTotalSpace') {
      bTotalSpace = nTotalSpace === oSnapshot.oCustomer.cust_ntotalspace;
    }

    if (cField !== 'nTotalUsers') {
      bTotalUsers = nTotalUsers === oSnapshot.oCustomer.cust_ntotalusers;
    }

    if (cField !== 'bAnnualPlan') {
      bCorrectPlan = (bAnnualPlan && oSnapshot.bAnnualPlan) || (!bAnnualPlan && !oSnapshot.bAnnualPlan);
    }

    if (bTotalSpace && bTotalUsers && bCorrectPlan) {
      this.setState({
        bHasChanged: this.modulesChanged()
      });
      return;
    }

    this.setState({
      bHasChanged: true
    });
  };

  cancelPlan = () => {
    const { setAlert } = this.props;

    Axios.delete(`customer/subscription`)
      .then(() => {
        setAlert('success', intl.get('Module.subscription_cancelled'));
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  cancelPlanShowConfirmation = () => {
    const { oCustomerSubscription } = this.state;

    this.setState({
      rcmpSweetAlert: (
        <SweetAlert
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={intl.get('Module.subscription_cancel_title')}
          onConfirm={() => {
            this.cancelPlan();
          }}
          onCancel={this.hideAlert}
          style={{ top: '35%' }}
        >
          <div className="confirmation-alert">
            {intl.get('Module.subscription_cancel_text', oCustomerSubscription.ca_dcontractend)}
          </div>
        </SweetAlert>
      )
    });
  };

  checkAnnualToMonthlyWithoutContractEnd = () => {
    const { oSnapshot, bAnnualPlan, oCustomerSubscription } = this.state;

    if (
      typeof oCustomerSubscription.ca_dcontractend === 'undefined' ||
      oCustomerSubscription.ca_dcontractend == null ||
      bAnnualPlan ||
      !oSnapshot.bAnnualPlan
    ) {
      return false;
    }

    const dtContractEnd = moment(oCustomerSubscription.ca_dcontractend);
    const dtNow = moment();

    return dtContractEnd > dtNow;
  };

  isNotNextContract = () => {
    const { oCustomerSubscription } = this.state;
    if (
      typeof oCustomerSubscription.ca_dnewcontractafter === 'undefined' ||
      oCustomerSubscription.ca_dnewcontractafter === null
    ) {
      return false;
    }

    const dtNewContractAfter = moment(oCustomerSubscription.ca_dnewcontractafter);
    const dtNow = moment();

    return dtNewContractAfter > dtNow;
  };

  render() {
    const {
      bLoading,
      aPages,
      bSending,
      oCustomerSubscription,
      aModules,
      aCustomerModulesIds,
      nTotalSpace,
      nTotalUsers,
      nMinSpace,
      nMinUserQuantity,
      bAnnualPlan,
      bIsAnyModuleSelected,
      rcmpSweetAlert,
      bHasChanged
    } = this.state;
    const fAnnualDiscount = this.state[this.CONFIGS_KEYS.annual_discount];
    const { history, getAlert } = this.props;

    return (
      <Page
        loading={bLoading}
        rcmpBreadcrumb={<Breadcrumb aPages={aPages} />}
        cTitle={intl.get('Module.title')}
        cImage={imgfConfig}
        rcmpCardHeader={<CardHeader history={history} />}
        className="v2-module"
      >
        <div className="alert-container">
          {rcmpSweetAlert}
          {getAlert()}
        </div>
        <div className="outer-container">
          <div>
            {oCustomerSubscription && !oCustomerSubscription.ca_trial && !oCustomerSubscription.ca_bispaid && (
              <Block
                imgfIcon={require(`../../assets/img/264-warning-gray.svg`)}
                cName={intl.get('Module.payment_pending_alert')}
                cDescription={intl.get('Module.payment_pending_message')}
                fnOnClick={() => {
                  Axios.get('customer/cashier/token')
                    .then(oResponse => {
                      this.openLinkInNewTab(oResponse.data.cLink);
                    })
                    .catch(oErrorResponse => {
                      //TODO: setar alerta e tratar o erro
                    });
                }}
                cExtraClass={'enabled centered warning-block'}
              >
                &nbsp;
              </Block>
            )}
            {oCustomerSubscription && this.isNotNextContract() && (
              <Block
                imgfIcon={require(`../../assets/img/264-warning-gray.svg`)}
                cName={intl.get('Module.to_next_plan_alert')}
                cDescription={intl.get('Module.to_next_plan_message', {
                  cContractEnd: moment(oCustomerSubscription.ca_dcontractend).format('DD.MM.YYYY')
                })}
                cExtraClass={'enabled centered warning-block'}
              ></Block>
            )}
          </div>
          <form className="form-payment">
            <div className="module-container">
              <div className="module-moduleList">
                <h5>{intl.get('Module.module_list_title')}</h5>
                {aModules.length > 0 &&
                  Object.keys(aModules).map(cKey => {
                      const moduleId = aModules[cKey].mod_nid;

                      return (
                          <ModuleBlock
                              key={moduleId}
                              imgfIcon={require(`../default-v2/img/icon-module-${aModules[cKey].mod_cslugname}.svg`)}
                              cName={aModules[cKey].mod_cname}
                              cDescription={aModules[cKey].mod_cdescription}
                              nValue={aModules[cKey].mod_nvalue}
                              bStartEnabled={
                                  aCustomerModulesIds.length > 0
                                      ? aCustomerModulesIds.includes(moduleId)
                                      : aModules[cKey].bEnabled
                              }
                              role="button"
                              tabIndex={0}
                              fnOnClick={() => {
                                  const dependentModuleId = moduleDependencies[moduleId];
                                  const isModuleEnabled = aModules[cKey].bEnabled;

                                  // Atualizar o estado do módulo atual
                                  aModules[cKey].bEnabled = !isModuleEnabled;

                                  // Se o módulo atual é "Painel de Qualidade" (mod_nid: 1) e está sendo desmarcado, desmarcar também "Gestão de Auditorias" (mod_nid: 3)
                                  if (moduleId === 1 && !aModules[cKey].bEnabled) {
                                      const auditManagementKey = aModules.findIndex(module => module.mod_nid === 3);
                                      if (auditManagementKey !== -1) {
                                          aModules[auditManagementKey].bEnabled = false;
                                      }
                                  }

                                  // Se o módulo atual é "Gestão de Auditorias" (mod_nid: 3) e está sendo ativado, marcar também "Painel de Qualidade" (mod_nid: 1)
                                  if (moduleId === 3 && aModules[cKey].bEnabled) {
                                      const qualityPanelKey = aModules.findIndex(module => module.mod_nid === 1);
                                      if (qualityPanelKey !== -1) {
                                          aModules[qualityPanelKey].bEnabled = true;
                                      }
                                  }

                                  // Se o módulo atual está sendo ativado, verifique e ative o módulo dependente, se houver
                                  if (aModules[cKey].bEnabled && dependentModuleId) {
                                      const dependentModuleKey = aModules.findIndex(module => module.mod_nid === Number(dependentModuleId));
                                      if (dependentModuleKey !== -1) {
                                          aModules[dependentModuleKey].bEnabled = true;
                                      }
                                  }

                                  // Atualize o estado geral do componente
                                  this.setState({
                                      nPaidValue: this.recalculatePaidValue(),
                                      bIsAnyModuleSelected: aModules.some(module => module.bEnabled)
                                  });

                                  this.customerHasChanged(null, null);
                              }}
                          />
                      );
                  })}
              </div>
              <div className={`module-extras${bIsAnyModuleSelected ? '' : ' disabled-container'}`}>
                <div className="module-extras-container">
                  <div className="module-extras-users">
                    <h5>{intl.get('Module.module_extras_users')}</h5>
                    <div className="module-extras-users-container">
                      <Input
                        type="number"
                        id="nTotalUsers"
                        name="nTotalUsers"
                        min={nMinUserQuantity}
                        onChange={evt => this.handleChange(evt)}
                        onKeyUp={evt => this.handleChange(evt)}
                        onBlur={evt =>
                          this.checkMinUserQuantity(evt, 'nTotalUsers', this.state.nMinUserQuantity)
                        }
                        value={nTotalUsers}
                        disabled={!bIsAnyModuleSelected}
                      />
                      <span
                        className="nTotalUsers-up"
                        onClick={this.totalUsersUp}
                        onKeyPress={this.totalUsersUp}
                        role="button"
                        tabIndex={0}
                      >
                        &#9650;
                      </span>
                      <span
                        className="nTotalUsers-down"
                        onClick={this.totalUsersDown}
                        onKeyPress={this.totalUsersDown}
                        role="button"
                        tabIndex={0}
                      >
                        &#9660;
                      </span>
                    </div>
                    <div className="users-border">&nbsp;</div>
                  </div>
                  {oCustomerSubscription && (
                    <div className="module-extras-space">
                      <h5>{intl.get('Module.module_extras_space')}</h5>
                      <div className="module-extras-space-container">
                        <Input
                          type="number"
                          id="nTotalSpace"
                          name="nTotalSpace"
                          min={nMinSpace}
                          onChange={evt => this.handleChange(evt)}
                          onKeyUp={evt => this.handleChange(evt)}
                          onBlur={evt =>
                            this.checkConstraintAndResetToMinimum(evt, 'nTotalSpace', this.state.nMinSpace)
                          }
                          value={nTotalSpace}
                          disabled={!bIsAnyModuleSelected}
                        />
                        <em className="nTotalSpace-text">Gb</em>
                        <div
                          className="nTotalSpace-up"
                          onClick={this.totalSpaceUp}
                          onKeyPress={this.totalSpaceUp}
                          role="button"
                          tabIndex={0}
                        >
                          &#9650;
                        </div>
                        <div
                          className="nTotalSpace-down"
                          onClick={this.totalSpaceDown}
                          onKeyPress={this.totalSpaceDown}
                          role="button"
                          tabIndex={0}
                        >
                          &#9660;
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`module-value-container${bIsAnyModuleSelected ? '' : ' disabled-container'}`}>
                {this.renderModuleValue()}
                <div className="module-buttonstack">
                  <div className="alt-label-v2">
                    <AltCheckbox
                      key="checkbox-anual"
                      onChange={evt => this.handleChangeCheckbox(evt)}
                      title={intl.get('Module.annual_plan', { nDiscountPercent: fAnnualDiscount })}
                      label={intl.get('Module.annual_plan', { nDiscountPercent: fAnnualDiscount })}
                      name="bAnnualPlan"
                      localeLabel="end"
                      checked={bAnnualPlan}
                      className="module-buttonstack-checkbox"
                      disabled={!bIsAnyModuleSelected}
                      style={{ width: '120px', marginBottom: '5px' }}
                    />
                  </div>
                  {!oCustomerSubscription && (
                    <Button
                      disabled={bSending || !bIsAnyModuleSelected}
                      type="button"
                      onClick={this.signupShowConfirmation}
                      className="module-button-change"
                    >
                      {intl.get('Module.signup')}
                    </Button>
                  )}

                  {oCustomerSubscription && (
                    <Button
                      disabled={
                        bSending ||
                        !bIsAnyModuleSelected ||
                        !bHasChanged ||
                        this.checkAnnualToMonthlyWithoutContractEnd()
                      }
                      type="button"
                      onClick={this.changePlan}
                      className="module-button-change"
                    >
                      {intl.get('Module.change_plan')}
                    </Button>
                  )}

                  {/* {oCustomerSubscription && !oCustomerSubscription.ca_dcancel && (
                    <Button
                      disabled={bSending}
                      type="button"
                      onClick={this.cancelPlan}
                      className="module-button-change"
                    >
                      {intl.get('Module.cancel_plan')}
                    </Button>
                  )} */}

                  {oCustomerSubscription && oCustomerSubscription.ca_dcancel && (
                    <p>
                      {intl.get('Module.subscription_expires_at', {
                        cContractDate: oCustomerSubscription.ca_dcontractend
                      })}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </Page>
    );
  }
}
export default Module;
