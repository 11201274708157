import React, { Component } from 'react';
import intl from 'react-intl-universal';
import './styles/header.scss';
import './styles/breadcrumb.scss';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import { Link } from 'react-router-dom';
import NavItem from './NavItem';
import Axios from '../../config/Axios';
import { signIn } from '../../config/Authentication';
import notificationImg from '../../assets/img/notification.svg';
import notificationImgHover from '../../assets/img/notification-hover.svg';
import imgfConfig from './img/icon-config.svg';
import imgfConfigHover from './img/icon-config-hover.svg';
import { can } from '../../config/Permissions';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import logout from '../../config/Logout';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const oLocalStorage = new EncodedLocalStorage();
class Header extends Component {
  bIsMounted = false;

  constructor(oProps) {
    super(oProps);

    this.state = {
      aItems: [],
      bLoading: true,
      bHasMessages: null,
      bStatusUpdated: false,
      bIsOpen: false,
      rcmpAlert: null
    };
  }

  componentDidMount() {
    this.bIsMounted = true;
    this.getNewMessages(false);
    this.getStatus();

    this.tmInterval = setInterval(() => {
      this.getNewMessages(true);
    }, 60000);
  }

  componentWillUnmount() {
    this.bIsMounted = false;
    clearInterval(this.tmInterval);
  }

  renderConfigMenu = () => {
    const { bIsOpen } = this.state;
    const cDisplay = bIsOpen ? 'block' : 'none';
    const cVersion = process.env.REACT_APP_VERSION;

    // Recuperar valores de localStorage
    const nCustId = sessionStorage.getItem('nCustId') || 'N/A';
    const cCNPJ = sessionStorage.getItem('cOldCompanyCNPJ') || 'N/A';

    if (!can('admin')) return '';

    return (
      <div style={{ display: cDisplay }} className="config-menu">
        <ul>
          <li key="navlink-informacoes-cadastrais">
            <Link to="/customer/update/" className="link">
              {intl.get('Nav.informacoes_cadastrais')}
            </Link>
          </li>

          <li key="navlink-planos">
            <Link to="/modules" className="link">
              {intl.get('Nav.planos')}
            </Link>
          </li>

          <li key="navlink-config-footer">
            <Link to="/document/configure" className="link">
              {intl.get('Nav.config_footer')}
            </Link>
          </li>

          <li key="navlink-extrato-financeiro">
            <Link to="/extract" className="link">
              {intl.get('Nav.extrato_financeiro')}
            </Link>
          </li>

          <li key="navlink-termo-adesao">
            <Link to="/terms" className="link">
              {intl.get('MyAccount.meu_termo_adesao')}
            </Link>
          </li>

          <li key="navlink-version" className="link navlink-version">
          <span className="bold">{cCNPJ} - ID:{nCustId}</span>  <br /> {intl.get('ConfigHeaderFooter.version')} {cVersion.replace('v', '')}
          </li>
        </ul>
      </div>
    );
  };

  getNewMessages = bIsAutomatic => {
    if (bIsAutomatic) {
      Axios.get('/notification/queryNotification', {
        params: {
          bNotRenewing: false
        }
      })
        .then(oResponse => {
          if (oResponse.data === true) {
            const elNotification = document.getElementById('v2-navitem-notification');
            if (!elNotification.classList.contains('new-notifications'))
              elNotification.classList.add('new-notifications');
          }
          if (this.bIsMounted) {
            this.setState({
              bHasMessages: oResponse.data
            });
          }
        })
        .catch(oError => {
          if (
            oError !== null &&
            oError !== undefined &&
            oError.response !== null &&
            oError.response !== undefined &&
            oError.response.status !== undefined &&
            oError.response.status === 401
          ) {
            this.tmInterval = null;
          }
        });
    } else {
      Axios.get('/notification/queryNotification')
        .then(oResponse => {
          if (oResponse.data === true) {
            const elNotification = document.getElementById('v2-navitem-notification');
            if (!elNotification.classList.contains('new-notifications'))
              elNotification.classList.add('new-notifications');
          }
          if (this.bIsMounted) {
            this.setState({
              bHasMessages: oResponse.data
            });
          }
        })
        .catch(oError => {
          if (
            oError !== null &&
            oError !== undefined &&
            oError.response !== null &&
            oError.response !== undefined &&
            oError.response.status !== undefined &&
            oError.response.status === 401
          ) {
            this.tmInterval = null;
          }
        });
    }
  };

  getStatus = () => {
    Axios.get(`/status/login`)
      .then(oResponse => {
        signIn(oResponse.data);
        oLocalStorage.decodeToken();
        if (
          oLocalStorage.get('bActive') === false &&
          oLocalStorage.get('bPaymentPending') === true &&
          oLocalStorage.get('bReceivesPaymentAlert') !== true &&
          window.location.pathname !== '/modules' &&
          !localStorage.getItem("automationUrl")) {
          window.location.href = '/modules';
        }
        if (localStorage.getItem("automationUrl")) { //redirect to automation page
          const automationUrl = localStorage.getItem("automationUrl");
          window.location.href = automationUrl

          localStorage.removeItem("automationUrl")
        }
        if (this.bIsMounted) {
          this.setState({
            bStatusUpdated: true
          });
        }
      })
      .catch(oError => {
        if (oError?.response?.status === 403) {
          this.setState({
            rcmpAlert: (
              <SweetAlert
                confirmBtnText={intl.get('ok')}
                title={intl.get('trial_title')}
                onConfirm={() => {
                  logout();
                }}
              >
                {intl.get('trial_message')}
              </SweetAlert>
            )
          });
        }
      });
  };

  renderNavItems() {
    const { history, oUser, cCurrentHeaderOption } = this.props;
    const { bHasMessages } = this.state;
    let cFantasyName = '';
    let rcmpNavConfig = null;
    let cFantasyNameClass = 'v2-navitem-company-name';
    if (oUser && oUser.customer) {
      cFantasyName = oUser.customer.cust_cfantasyname;
    }

    // configurations
    /* if (can('admin')) { */
    cFantasyNameClass = '';
    rcmpNavConfig = (
      <NavItem
        bShowItem={can('admin')}
        key="navitem-config"
        id="v2-navitem-config"
        cIcon={cCurrentHeaderOption === intl.get('configs') ? imgfConfigHover : imgfConfig}
        cText={intl.get('configs')}
        onClick={evtClick => {
          this.setState({
            bIsOpen: !this.state.bIsOpen
          });
        }}
      >
        <OutsideHandlerClick
          handleClickOutside={evtClickOutside => {
            this.setState({
              bIsOpen: false
            });
          }}
        >
          {this.renderConfigMenu()}
        </OutsideHandlerClick>
      </NavItem>
    );
    /* } */

    const rcmpNavNotification = (
      <NavItem
        key="navitem-notification"
        id="v2-navitem-notification"
        cIcon={cCurrentHeaderOption === intl.get('Notification.title') ? notificationImgHover : notificationImg}
        cText={intl.get('Notification.title')}
        cLink="/notification"
        bHasAlert={bHasMessages}
        onClick={evtClick => {
          evtClick.preventDefault();
          history.push('/notification');
        }}
      >
        <span />
      </NavItem>
    );
    const rcmpNavCompany = (
      <NavItem key="navitem-company" id="v2-navitem-company" cText={cFantasyName} className={cFantasyNameClass} />
    );

    return (
      <>
        {rcmpNavConfig}
        {rcmpNavNotification}
        {rcmpNavCompany}
      </>
    );
  }

  render() {
    const { cTitle, cDescription, rcmpBreadcrumb, cImage, oUser } = this.props;
    const { rcmpAlert } = this.state;

    let bCheckIfBreadcrumbExists = false;
    if (
      rcmpBreadcrumb?.props?.children?.length > 1 ||
      rcmpBreadcrumb?.props?.children?.props?.children?.length > 1 ||
      rcmpBreadcrumb?.props?.aPages?.length >= 3
    ) {
      bCheckIfBreadcrumbExists = true;
    }

    return (
      <>
        <header id="v2-header" className={bCheckIfBreadcrumbExists ? 'removeMarginTop' : ''}>
          <div id="v2-header-content">
            {bCheckIfBreadcrumbExists && <div>{rcmpBreadcrumb}</div>}
            <div
              className="flex"
              style={{
                marginBottom: bCheckIfBreadcrumbExists && !cDescription ? 18 : 0,
                marginTop: bCheckIfBreadcrumbExists && !cDescription ? 5 : 3
              }}
            >
              <img src={cImage} alt="section icon" />
              {!cDescription && <h1 className="v2-header-title">{cTitle}</h1>}
              {cDescription && (
                <div className="v2-header-title-description-wrapper">
                  <h1 className="v2-header-title">{cTitle}</h1>
                  <span>.</span>
                  <h5>{cDescription}</h5>
                </div>
              )}
            </div>
          </div>
          <div className="v2-header-menu">
            <ul className="v2-header-navitems">{this.renderNavItems()}</ul>
            <strong className="cUser"> {oUser.customer.cust_cfantasyname}</strong>
          </div>
        </header>
        {rcmpAlert}
      </>
    );
  }
}

Header.defaultProps = {
  cCurrentHeaderOption: ''
};

export default Header;
