import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import { Flex } from '@stardust-ds/react';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import './styles/applications.scss';
import Axios from '../../config/Axios';
import PlusIcon from '../../assets/icons/PlusIcon';
import CopyIcon from '../../assets/icons/CopyIcon';
import Drawer from '../../components/Drawer';
import imgfLoading from '../../assets/img/preloader-black.gif';
import { can } from '../../config/Permissions';
import ModalDelete from '../../components/ModalDelete';
import { toast, Typography } from '@stardust-ds/react';
import Checkbox from './components/Checkbox';
import ModalApplicationLogs from './components/ModalApplicationLogs';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const oLocalStorage = new EncodedLocalStorage();

export class Applications extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoadingApplications: false,
      bLoadingPermissions: false,
      bLoadingCreate: false,
      bIsSubmited: false,
      oCreatedApplicationToken: null,
      bIsOpenCreate: false,
      oApplicationRevoke: null,
      bIsLoadingRevoke: false,
      oSelectedApplicationLog: null,
      aApplications: [],
      aPermissions: [],
      oApplication: {
        cname: '',
        aPermissions: []
      }
    };
  }

  componentDidMount = () => {
    if (!can('admin')) return;

    this.handleFetchApplications();
  };

  inputChangeHandler = evt => {
    const { oApplication } = this.state;

    if (evt.target.type === 'checkbox') {
      oApplication.aPermissions[evt.target.name] = evt.target.checked;
      this.setState({
        oApplication
      });
    } else if (evt.target.value !== null) {
      oApplication[evt.target.name] = evt.target.value;
      this.setState({
        oApplication
      });
    }
  };

  handleOpenCreate = () => {
    this.setState({ bIsOpenCreate: true });
    this.handleFetchPermissions();
  };

  handleCloseCreate = () => {
    const { bLoadingCreate } = this.state;
    if (bLoadingCreate) return;

    this.setState({ bIsOpenCreate: false, oCreatedApplicationToken: null, bIsSubmited: false });
  };

  handleFetchApplications = () => {
    this.setState({ bLoadingApplications: true });
    Axios.get('/customerapplication')
      .then(response => {
        const oResData = response?.data?.data || [];

        this.setState({
          bLoadingApplications: false,
          aApplications: oResData
        });
      })
      .catch(oError => {
        this.setState({ bLoadingApplications: false });
        toast({
          type: 'error',
          title: intl.get('Customers.Applications_tokens.title'),
          description: oError?.response?.data?.message || intl.get('Customers.Applications_tokens.list.error')
        });
      });
  };

  handleFetchPermissions = () => {
    const { aPermissions } = this.state;

    if (aPermissions.length > 0) return;

    this.setState({ bLoadingPermissions: true });

    Axios.get('/applicationpermission')
      .then(response => {
        const oResData = response?.data?.data || [];

        this.setState({
          bLoadingPermissions: false,
          aPermissions: oResData
        });
      })
      .catch(oError => {
        this.setState({ bLoadingPermissions: false });
        toast({
          type: 'error',
          title: intl.get('Customers.Applications_tokens.create.permissions'),
          description:
            oError?.response?.data?.message || intl.get('Customers.Applications_tokens.create.error_list_permissions')
        });
      });
  };

  handleSubmitApplication = evt => {
    evt.preventDefault();
    evt.stopPropagation();

    this.setState({ bIsSubmited: true });

    const aSelectedPermissions = this.getSelectedPermissionsIds();
    if (!aSelectedPermissions.length) return;

    const { oApplication } = this.state;

    this.setState({ bLoadingCreate: true });

    Axios.post('/customerapplication', {
      cname: oApplication.cname,
      aPermissions: aSelectedPermissions
    })
      .then(response => {
        this.setState({
          bLoadingCreate: false,
          oCreatedApplicationToken: response?.data?.data || null
        });
        this.handleFetchApplications();
      })
      .catch(oError => {
        this.setState({ bLoadingCreate: false });
        toast({
          type: 'error',
          title: intl.get('Customers.Applications_tokens.title'),
          description: oError?.response?.data?.message || intl.get('Customers.Applications_tokens.create.error')
        });
      });
  };

  getSelectedPermissionsIds = () => {
    const { oApplication, aPermissions } = this.state;

    const aPermissionsSlugs = Object.keys(oApplication.aPermissions).filter(cKey => oApplication.aPermissions[cKey]);

    const flatSelectedPermissions = aPermissions.reduce((aAcc, oPermission) => {
      if (aPermissionsSlugs.includes(oPermission.appperm_cslug)) {
        aAcc.push(oPermission);
      }

      if (oPermission?.children_permissions?.length) {
        for (let oChildPermission of oPermission?.children_permissions) {
          if (aPermissionsSlugs.includes(oChildPermission.appperm_cslug)) {
            if (!aAcc.includes(oPermission)) {
              aAcc.push(oPermission);
            }

            aAcc.push(oChildPermission);
          }
        }
      }

      return aAcc;
    }, []);

    return flatSelectedPermissions.map(oPermission => oPermission.appperm_nid);
  };

  handleCopyToken = cToken => {
    navigator.clipboard
      .writeText(cToken)
      .then(() => {
        toast({
          type: 'info',
          title: intl.get('Customers.Applications_tokens.list.success_copy')
        });
      })
      .catch(() => {
        toast({
          type: 'error',
          title: intl.get('Customers.Applications_tokens.list.error_copy')
        });
      });
  };

  handleOnOpenRevoke = oApplication => {
    this.setState({ oApplicationRevoke: oApplication });
  };

  handleOnCloseRevoke = () => {
    const { bIsLoadingRevoke } = this.state;
    if (bIsLoadingRevoke) return;

    this.setState({ oApplicationRevoke: null });
  };

  handleRevokeApplication = cPassword => {
    const { oApplicationRevoke, bIsLoadingRevoke } = this.state;
    if (bIsLoadingRevoke) return;

    this.setState({ bIsLoadingRevoke: true });
    Axios.delete(`/customerapplication/${oApplicationRevoke.nid}`, { data: { cPassword } })
      .then(() => {
        this.setState({ oApplicationRevoke: null, bIsLoadingRevoke: false });
        this.handleFetchApplications();
        toast({
          type: 'success',
          title: intl.get('Customers.Applications_tokens.revoke.success')
        });
      })
      .catch(oError => {
        this.setState({ bIsLoadingRevoke: false });

        if (oError?.response?.data?.message !== 'Senha inválida') this.setState({ oApplicationRevoke: null });

        toast({
          type: 'error',
          title: intl.get('Customers.Applications_tokens.revoke.error'),
          description: oError?.response?.data?.message || null
        });
      });
  };

  handleOpenLogs = oApplication => {
    this.setState({ oSelectedApplicationLog: oApplication });
  };

  handleCloseLogs = () => {
    this.setState({ oSelectedApplicationLog: null });
  };

  cPermissions = oApplication => {
    if (!oApplication?.apermissions?.length) return null;

    const maxPermissionsLength = 75;
    const aRemoveRootPermissions = oApplication.apermissions.filter(oPermission => oPermission?.nid_parent);
    const cJoinPermissions = aRemoveRootPermissions.map(oPermission => oPermission.cname).join(', ');
    return {
      full: cJoinPermissions,
      truncate:
        cJoinPermissions.length > maxPermissionsLength
          ? `${cJoinPermissions.substring(0, maxPermissionsLength)}...`
          : cJoinPermissions
    };
  };

  render() {
    const {
      bIsOpenCreate,
      bLoadingApplications,
      aApplications,
      bLoadingPermissions,
      aPermissions,
      bLoadingCreate,
      bIsSubmited,
      oCreatedApplicationToken,
      oApplicationRevoke,
      bIsLoadingRevoke,
      oSelectedApplicationLog
    } = this.state;

    const cApiDocumentation = process.env.REACT_APP_ACREDITESE_API_DOCUMENTATION_URL;
    return (
      <>
        <fieldset>
          <div className="customer-head">
            <legend>{intl.get('Customers.Applications_tokens.title')}</legend>
          </div>

          <div className="customer-info">
            <div className="content-container">
              <Flex width="100%" alignItems="center" justifyContent="space-between" flexWrap="wrap" gap="16px">
                <div className="about-api-section">
                  <p>
                    {intl.get('Customers.Applications_tokens.about_api')}{' '}
                    {/* todo: white label - desabilitar? */}
                    <a href={cApiDocumentation} target="_blank" className="link">
                      {intl.get('Customers.Applications_tokens.link_api_documentation')}
                    </a>
                  </p>
                </div>
                <Button type="button" className="new-token-button" size="sm" onClick={this.handleOpenCreate}>
                  <PlusIcon isRounded={false} />
                  {intl.get('Customers.Applications_tokens.new_token')}
                </Button>
              </Flex>
              {bLoadingApplications && <img className="loading" src={imgfLoading} alt="loading" />}
              {!bLoadingApplications && !aApplications?.length && (
                <p>
                  <th>{intl.get('Customers.Applications_tokens.list.no_tokens')}</th>
                </p>
              )}
              {!bLoadingApplications && aApplications.length > 0 && (
                <div className="applications-table">
                  <table>
                    <thead>
                      <tr>
                        <th>{intl.get('Customers.Applications_tokens.list.name')}</th>
                        <th>{intl.get('Customers.Applications_tokens.list.key')}</th>
                        <th>{intl.get('Customers.Applications_tokens.list.created')}</th>
                        <th>{intl.get('Customers.Applications_tokens.list.permissions')}</th>
                        <th>{intl.get('Customers.Applications_tokens.list.last_access')}</th>
                        <th>{intl.get('Customers.Applications_tokens.list.action')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {aApplications.map((oApplication, nIndex) => (
                        <tr key={`customer-application-${nIndex}`}>
                          <td>{oApplication.cname}</td>
                          <td>
                            <div className="customer-application-token">
                              {oApplication.access_token
                                ? `${oApplication.access_token?.substring(0, 5)}****************` || ''
                                : ''}
                              <span
                                className="copy-button"
                                onClick={() => this.handleCopyToken(oApplication.access_token)}
                              >
                                <CopyIcon />
                                {intl.get('Customers.Applications_tokens.list.copy')}
                              </span>
                            </div>
                          </td>
                          <td>
                            {moment
                              .parseZone(oApplication?.dcreated)
                              .tz(oLocalStorage.get('cTimezone'))
                              .format('DD/MM/YYYY HH:mm')}
                          </td>
                          <td title={this.cPermissions(oApplication)?.full}>
                            {this.cPermissions(oApplication)?.truncate}
                          </td>
                          <td>
                            {oApplication.dlast_access
                              ? moment
                                  .parseZone(oApplication?.dlast_access)
                                  .tz(oLocalStorage.get('cTimezone'))
                                  .format('DD/MM/YYYY HH:mm')
                              : '00/00/0000 - 00:00'}
                          </td>
                          <td>
                            <span className="revoke-button" onClick={() => this.handleOnOpenRevoke(oApplication)}>
                              {intl.get('Customers.Applications_tokens.list.revoke')}
                            </span>
                            <span className="log-button" onClick={() => this.handleOpenLogs(oApplication)}>
                              Logs
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </fieldset>
        <Drawer
          isOpen={bIsOpenCreate}
          title={intl.get('Customers.Applications_tokens.create.title')}
          onClose={this.handleCloseCreate}
        >
          {!oCreatedApplicationToken && (
            <form id="create-application" onSubmit={this.handleSubmitApplication}>
              <div className="new-application-container">
                <label className="application-input">
                  {intl.get('Customers.Applications_tokens.create.name')}
                  <Input
                    type="text"
                    name="cname"
                    id="app_cname"
                    placeholder="Digite..."
                    required
                    onChange={evt => this.inputChangeHandler.call(this, evt)}
                  />
                </label>

                <div className="applications-permissions">
                  <h3>{intl.get('Customers.Applications_tokens.create.permissions')}</h3>
                  {bLoadingPermissions && <img className="loading" src={imgfLoading} alt="loading" />}

                  {!bLoadingPermissions && (
                    <div className="permissions-container">
                      {aPermissions.map((oPermission, nIndex) => (
                        <div className="permission" key={`application-permission-${nIndex}`}>
                          <p>{oPermission.appperm_cname}</p>
                          <div>
                            {oPermission?.children_permissions?.map((oPermissionItem, nIndex) => (
                              <Checkbox
                                key={`application-permission-item-${nIndex}`}
                                name={oPermissionItem.appperm_cslug}
                                value={oPermissionItem.appperm_nid}
                                checked={this.state.oApplication.aPermissions[oPermissionItem.appperm_cslug]}
                                onChange={evt => this.inputChangeHandler.call(this, evt)}
                                cLabel={oPermissionItem.appperm_cname}
                              />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {bIsSubmited && !this.getSelectedPermissionsIds()?.length && (
                    <Typography color="#FF3541" type="p3">
                      {intl.get('Customers.Applications_tokens.create.required_permission')}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="application-footer-container">
                <Button
                  type="button"
                  className="application-button outline"
                  onClick={this.handleCloseCreate}
                  disabled={bLoadingCreate}
                >
                  {intl.get('cancel')}
                </Button>
                <Button
                  form="create-application"
                  type="submit"
                  className="application-button"
                  disabled={bLoadingCreate}
                >
                  {intl.get('Customers.Applications_tokens.create.create_button')}
                </Button>
              </div>
            </form>
          )}

          {oCreatedApplicationToken && (
            <div className="new-application-container">
              <div className="new-application-texts-container">
                <h3 className="created-application-title">
                  {intl.get('Customers.Applications_tokens.create.success')}
                </h3>
                <p className="created-application-description">
                  {intl.get('Customers.Applications_tokens.create.success_description')}
                </p>

                <div className="created-application-token">{oCreatedApplicationToken}</div>
              </div>

              <div className="created-application-footer-buttons">
                <Button type="button" className="application-button outline" onClick={this.handleCloseCreate}>
                  {intl.get('close')}
                </Button>
                <Button
                  type="button"
                  className="application-button"
                  onClick={() => this.handleCopyToken(oCreatedApplicationToken)}
                >
                  <CopyIcon width={16} height={16} stroke="#ffffff" strokeWidth={2} />
                  {intl.get('Customers.Applications_tokens.create.copy_button')}
                </Button>
              </div>
            </div>
          )}
        </Drawer>
        <ModalDelete
          isOpen={!!oApplicationRevoke}
          title={intl.get('Customers.Applications_tokens.revoke.title')}
          description={
            oApplicationRevoke
              ? intl.get('Customers.Applications_tokens.revoke.description', { name: oApplicationRevoke.cname })
              : ''
          }
          isLoading={bIsLoadingRevoke}
          onClose={this.handleOnCloseRevoke}
          onConfirm={this.handleRevokeApplication}
        />
        <ModalApplicationLogs
          isOpen={!!oSelectedApplicationLog}
          oApplication={oSelectedApplicationLog}
          onClose={this.handleCloseLogs}
        />
      </>
    );
  }
}

export default Applications;
